/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, {
  ChangeEvent,
  Dispatch,
  ReactElement,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import {
  PayPalButtons,
  PayPalScriptProvider,
  usePayPalScriptReducer,
} from '@paypal/react-paypal-js';
import {
  Box,
  Grid,
  Typography,
  Button,
  useMediaQuery,
  useTheme,
  Card,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { Layout } from 'src/layouts';
import { Image } from 'src/components/atoms';
import { cardTypes } from 'src/utils/data';
import { QuoteContext } from 'src/views/Quote/QuoteContext';
import {
  QuoteShowModal,
  RequestAuthPayPalDiag,
  RequestAuthPayPalRepair,
  RequestConfirmAppointment,
  ResponseAppointment,
  CardOnFile,
} from 'src/types';

import { IReduxState } from 'src/store/reducers';
import mixPanel from 'src/utils/mixpanel';
import { MIXPANEL_TRACK } from 'src/utils/consts';

import { confirmAppointment } from 'src/api/quote';
import { setAppointment } from 'src/store/actions';
import { callAdsBooking } from 'src/utils/ads';
import images from 'src/assets/payment';
import moment from 'moment';
import callApi from 'src/api/request';
import useStyles from './styles';
import CheckoutForm from './CheckoutForm';

interface ModalFinishBookingProps {
  show: boolean;
  onClose: () => void;
  onConfirmCard: () => void;
}

export const PaypalContent = ({
  payPalTotal,
  handlePayPalCheckout,
  setPayPalError,
}: {
  payPalTotal: number;
  handlePayPalCheckout: (
    order_id: string,
    auth_id: string,
    payer_id: string
  ) => void;
  setPayPalError: Dispatch<SetStateAction<string | undefined>>;
}) => {
  const [{ isRejected }] = usePayPalScriptReducer();
  const classes = useStyles();

  useEffect(() => {
    if (isRejected)
      setPayPalError('There was an error initializing PayPal payment.');
  }, [isRejected, setPayPalError]);

  return (
    <>
      <Image src={images.paypal} />
      <p>Pay in interest-free installments</p>
      <PayPalButtons
        style={{ color: 'silver' }}
        onClick={() => mixPanel(MIXPANEL_TRACK.CLICKED_PAYPAL)}
        className={classes.paypalButton}
        createOrder={(data, actions) => {
          return actions.order.create({
            intent: 'AUTHORIZE',
            purchase_units: [
              {
                amount: {
                  value: payPalTotal.toString(),
                  currency_code: 'USD',
                },
              },
            ],
          });
        }}
        onApprove={(data, actions) => {
          if (actions.order) {
            return actions.order.authorize().then((details) => {
              const orderId = details.id;
              const payerId = details?.payer?.payer_id;
              let authorizationId: any;

              if (
                details?.purchase_units &&
                details.purchase_units[0] &&
                details.purchase_units[0].payments?.authorizations
              ) {
                authorizationId =
                  details.purchase_units[0].payments.authorizations[0].id;
              }

              if (orderId && authorizationId && payerId) {
                handlePayPalCheckout(orderId, authorizationId, payerId);
              }
            });
          }
          return Promise.resolve();
        }}
        onError={() =>
          setPayPalError('Error checking out with PayPal, please try again.')
        }
      />
    </>
  );
};

const ModalFinishBooking = (props: ModalFinishBookingProps): ReactElement => {
  const { show, onClose, onConfirmCard } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const [value, setValue] = useState(0);

  const handleChange = (
    event: ChangeEvent<{ name?: string | undefined; value: unknown }>,
    newValue: number
  ) => {
    setValue(newValue);
  };

  const appointment = useSelector(
    (state: IReduxState) => state.quote.appointment
  );

  const {
    handleConfirmPaypal,
    requestInProgress,
    handleShowModal,
    login,
    isLoggedIn,
    membershipSelected,
  } = useContext(QuoteContext);

  const stripe = useStripe();
  const elements = useElements();
  const [errors, setErrors] = useState<string | undefined | null>(null);
  const [payPalTotal, setPayPalTotal] = useState<number>(0);
  const [stripeRequestInProgress, setStripeRequestInProgress] = useState(false);
  const [payPalError, setPayPalError] = useState<string | undefined>();
  const [message, setMessage] = useState<string | undefined>();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const estimate = useSelector(
    (state: IReduxState) => state.quote.appointment?.attributes.estimate
  );
  const isMember = appointment?.attributes?.member_job || false;

  const appointmentType = appointment?.attributes.appointment_type;
  const isDiag: boolean = appointmentType === 'diagnosis';
  const memberType = appointment?.attributes.member_type;

  const cardOnFile: boolean | undefined | null =
    isLoggedIn || appointment?.attributes.credit_card_present;
  const [isCardOnFile, setIsCardOnFile] = useState<boolean>(false);
  const [lastFour, setLastFour] = useState<string>('');
  const [cardBrand, setCardBrand] = useState<string>('');
  const [prevCardSelected, setPrevCardSelected] = useState(false);
  const [errorCardOnFile, setErrorCardOnFile] = useState<
    string | undefined | null
  >(null);

  const handleCheckboxChange = () => {
    setPrevCardSelected(!prevCardSelected);
  };

  useEffect(() => {
    const userId = appointment?.attributes.user_id;

    if (userId) {
      callApi<CardOnFile>({
        url: `/api/v2/user/card_on_file/${userId}`,
        method: 'GET',
      }).then((response) => {
        if (response.errors && response.errors.length > 0) {
          setErrorCardOnFile(
            'An error occurred while processing your request.'
          );
        } else if (
          response.card_on_file?.card_brand &&
          response.card_on_file?.last_4_digits
        ) {
          setIsCardOnFile(true);
          setLastFour(response.card_on_file?.last_4_digits as string);
          setCardBrand(response.card_on_file?.card_brand);
        }
      });
    }
  }, [appointment?.attributes.user_id]);

  useEffect(() => {
    const getPayPalTotal = () => {
      let price = 0;

      if (isDiag) {
        if (memberType === 'Unlimited') {
          price += 0;
        } else {
          const diagPrice = isMember
            ? appointment?.attributes.diagnosis_price_member
            : appointment?.attributes.diagnosis_price;

          if (diagPrice) {
            price += diagPrice;
          }
        }
      }

      const servicePrice = isMember
        ? estimate?.total_member_price
        : estimate?.total_price;

      if (servicePrice) {
        price += servicePrice;
      }

      return price || 0;
    };

    setPayPalTotal(getPayPalTotal() || 0);
  }, [
    estimate,
    isDiag,
    appointment?.attributes.diagnosis_price,
    appointment?.attributes.diagnosis_price_member,
    appointment?.attributes.ppi_fee,
    isMember,
    memberType,
  ]);

  const handlePayPalCheckout = async (
    order_id: string,
    auth_id: string,
    payer_id: string
  ) => {
    if (isDiag) {
      const data: RequestAuthPayPalDiag = {
        paypal_diag_order_id: order_id,
        paypal_diag_auth_id: auth_id,
        paypal_payer_id: payer_id,
      };

      await handleConfirmPaypal(data);
    } else {
      const data: RequestAuthPayPalRepair = {
        paypal_repair_order_id: order_id,
        paypal_repair_auth_id: auth_id,
        paypal_payer_id: payer_id,
      };

      await handleConfirmPaypal(data);
    }
  };

  const handleCheckOutNew = async () => {
    if (!stripe || !elements || !appointment) {
      return;
    }

    setStripeRequestInProgress(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `https://app.goodhood.auto/appointment/confirmed/${appointment.id}`,
      },
    });

    if (error) {
      if (error.type === 'card_error' || error.type === 'validation_error') {
        setMessage(error.message);
      } else {
        setMessage(
          'Error processing your request. Please contact us or use a different payment method.'
        );
      }

      setTimeout(() => {
        setMessage(undefined);
      }, 4000);
    }

    setStripeRequestInProgress(false);
  };

  const handleCheckOut = async () => {
    setStripeRequestInProgress(true);
    setErrors(null);

    if (!appointment?.id) {
      setStripeRequestInProgress(false);
      return false;
    }

    if (!stripe || !elements) {
      setStripeRequestInProgress(false);
      return false;
    }

    const cardElem = elements.getElement(CardElement);

    if (!cardElem) {
      setStripeRequestInProgress(false);
      return false;
    }

    const { token } = await stripe.createToken(cardElem);

    if (token) {
      const data: RequestConfirmAppointment = {
        token: token.id,
      };

      if (membershipSelected) {
        if (membershipSelected === '$180') {
          data.member_price_agreed_on_before_job = 180;
        }
      }

      await confirmAppointment(appointment.id, data)
        .then((resp: ResponseAppointment) => {
          if (resp.errors) {
            setErrors(resp.errors.join(', '));
            setStripeRequestInProgress(false);
          } else {
            dispatch(setAppointment(resp.data));
            callAdsBooking(resp.data);
            login(resp);
            handleShowModal(QuoteShowModal.NONE);
            setStripeRequestInProgress(false);
          }
        })
        .catch(() => {
          setStripeRequestInProgress(false);
        });
    } else {
      setStripeRequestInProgress(false);
      setErrors('Quote confirmation failed');
    }

    mixPanel(MIXPANEL_TRACK.CARD_INFO);

    setStripeRequestInProgress(false);

    return true;
  };

  const yrsOfExperience = appointment?.attributes.mechanic.years_of_experience;
  const yrsOfExperienceTxt = yrsOfExperience
    ? `Mechanic • ${yrsOfExperience} years of experience`
    : 'Mechanic';

  const appointmentItems = [
    {
      icon: <Image src={images.mechanic} />,
      text: appointment?.attributes.mechanic.name,
      subText: yrsOfExperienceTxt,
    },
    {
      icon: <Image src={images.calendar} />,
      text: moment(appointment?.attributes.appointment_day).format(
        'dddd, MMM D'
      ),
    },
    {
      icon: <Image src={images.clock} />,
      text: appointment?.attributes.appointment_time
        ?.split('-')
        .map((hour) => moment(hour, 'hh:mma').format('h a'))
        .join(' - '),
    },
    {
      icon: <Image src={images.pin} />,
      text: appointment?.attributes.exact_address,
    },
    {
      icon: <Image src={images.coin} />,
      text: `$${payPalTotal}`,
      subText: 'to be charged when work is done',
    },
  ];

  return (
    <Dialog
      open={show}
      onClose={onClose}
      aria-labelledby="responsive-dialog-title"
      scroll="body"
      fullScreen
      className={classes.root}
    >
      <Layout>
        <div className={classes.wrapper}>
          <DialogContent style={{ padding: 0 }}>
            <Grid
              container
              spacing={4}
              style={{
                width: '100%',
                margin: '32px auto 32px auto',
                maxWidth: '1150px',
              }}
            >
              <Grid
                container
                item
                md={8}
                sm={12}
                xs={12}
                className={classes.contentHolder}
              >
                <Box key="payment" className={classes.boxInformation}>
                  <Box
                    key="image-payments"
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="flex-start"
                    gridGap={16}
                  >
                    {cardTypes.map((ct) => (
                      <Image key={ct.title} src={ct.image} />
                    ))}
                  </Box>
                  <Box>
                    <h1 className={classes.boxTitle}>
                      {cardOnFile ? 'Confirm' : 'Payment'}
                    </h1>
                  </Box>
                  <Box key="action-payment">
                    <p className={classes.textPayment2}>
                      {cardOnFile
                        ? 'Confirm appointment details.'
                        : 'Choose your payment method.'}
                      <br />
                      You <b>won&apos;t be charged</b> until the day of service.
                    </p>

                    <div>
                      {isCardOnFile && (
                        <Card className={classes.cardOnFile} elevation={0}>
                          <Typography
                            style={{ fontFamily: 'Inter', fontWeight: 600 }}
                          >
                            Use card on file
                          </Typography>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={prevCardSelected}
                                onChange={handleCheckboxChange}
                                color="primary"
                              />
                            }
                            label={`${cardBrand} ending ${lastFour}`}
                          />
                        </Card>
                      )}

                      {!prevCardSelected && (
                        <CheckoutForm errors={errors} message={message} />
                      )}
                      <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        className={classes.reservationButton}
                        onClick={
                          isCardOnFile && prevCardSelected
                            ? onConfirmCard
                            : handleCheckOut
                        }
                        disabled={requestInProgress || stripeRequestInProgress}
                      >
                        Pay with card
                        <Image src={images.arrowRight} />
                      </Button>
                    </div>
                  </Box>
                </Box>
                {!payPalError && appointment?.attributes.estimate && (
                  <>
                    {payPalTotal > 0 ? (
                      <>
                        <Box className={classes.paymentDivider}>
                          <span className={classes.dividingLine} />
                          <p>or</p>
                          <span className={classes.dividingLine} />
                        </Box>
                        <Box
                          style={{ margin: '16px 0' }}
                          className={classes.paypalWrapper}
                        >
                          <PayPalScriptProvider
                            options={{
                              clientId:
                                process.env.REACT_APP_PAYPAL_CLIENT_ID || '',
                              intent: 'authorize',
                              'disable-funding': 'card',
                              'enable-funding': 'paylater',
                              components: 'messages,buttons',
                            }}
                          >
                            <PaypalContent
                              payPalTotal={payPalTotal}
                              handlePayPalCheckout={handlePayPalCheckout}
                              setPayPalError={setPayPalError}
                            />
                          </PayPalScriptProvider>
                          {payPalError && (
                            <Typography
                              color="error"
                              style={{
                                margin: '5px 0',
                                textAlign: 'center',
                                fontFamily: 'Inter',
                              }}
                            >
                              {payPalError}
                            </Typography>
                          )}
                        </Box>
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </Grid>
              <Grid
                item
                container
                md={4}
                sm={12}
                xs={12}
                className={classes.contentHolder}
              >
                <Box className={classes.appointmentBox}>
                  <h2>Summary</h2>
                  <ul className={classes.appointmentDetails}>
                    {appointmentItems.map((item) => (
                      <li key={item.text}>
                        <div className={classes.appointmentIcon}>
                          {item.icon}
                        </div>
                        {item.subText ? (
                          <p className={classes.splitText}>
                            <b>{item.text}</b>
                            <br />
                            {item.subText}
                          </p>
                        ) : (
                          <p className={classes.text}>{item.text}</p>
                        )}
                      </li>
                    ))}
                  </ul>
                </Box>
                <Button className={classes.callButton}>
                  <a href="tel:469-840-3990">
                    <Image src={images.phone} />
                    Have a question? Call Us
                  </a>
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
        </div>
      </Layout>
    </Dialog>
  );
};

ModalFinishBooking.defaultProps = {};

export default ModalFinishBooking;
