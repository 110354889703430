import {
  Button,
  Card,
  CardContent,
  Chip,
  FormControl,
  Tab,
  Tabs,
  Typography,
  makeStyles,
  InputAdornment,
} from '@material-ui/core';
import { Autocomplete, TextField } from '@mui/material';
import {
  AddCircle,
  ArrowForward,
  Cancel,
  CheckCircle,
} from '@material-ui/icons';
import { styled } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import {
  quoteOptions,
  staticServiceList,
  allStaticServices,
} from 'src/utils/data';
import { useDispatch, useSelector } from 'react-redux';
import { IReduxState } from 'src/store/reducers';
import { RequestCreateAppointment } from 'src/types';
import { createFleetAppointment } from 'src/api/quote';
import { setAppointment } from 'src/store/actions';
import mixPanel from 'src/utils/mixpanel';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    width: '50%',
    margin: 'auto',
    marginTop: 50,
    boxShadow: '0 0 5px rgba(0, 0, 0, 0.3)',
    background: '#fff',
    borderRadius: 5,
    '@media (max-width: 1450px)': {
      width: '100%',
    },
  },
  font: {
    fontSize: '18px',
    fontFamily: 'Inter',
  },
  fontSmall: {
    fontSize: '14px',
    fontFamily: 'Inter',
  },
  chipSelected: {
    margin: theme.spacing(0.5),
    background: '#B3CDCD',
    color: '#005959',
    fontWeight: 600,
    fontFamily: 'Inter',
  },
  chip: {
    margin: theme.spacing(0.5),
    fontFamily: 'Inter',
  },
  chipDiagnoses: {
    margin: theme.spacing(0.5),
    fontFamily: 'Inter',
    overflowY: 'auto',
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('sm')]: {
      overflowY: 'auto',
      whiteSpace: 'nowrap',
    },
  },
  selectedChips: {
    display: 'flex',
    flexWrap: 'wrap',
    overflowY: 'auto',
    whiteSpace: 'nowrap',
    marginTop: 80,
    [theme.breakpoints.down('sm')]: {
      overflowY: 'auto',
      whiteSpace: 'nowrap',
      maxHeight: 200,
      marginTop: 80,
    },
  },
  selectedChipsRepair: {
    display: 'flex',
    flexWrap: 'wrap',
    overflowY: 'auto',
    whiteSpace: 'nowrap',
    marginTop: 20,
    [theme.breakpoints.down('sm')]: {
      overflowY: 'auto',
      whiteSpace: 'nowrap',
      maxHeight: 200,
    },
  },
  cancelIcon: {
    background: '#B3CDCD',
    color: '#005959',
    '&:hover': {
      color: '#E74D4D',
    },
  },
  addIcon: {
    color: '#337A7A',
    '&:hover': {
      color: '#005959',
    },
  },
  buttonForward: {
    color: '#fff',
    margin: '0px 20px 20px 20px',
    padding: '6px 24px',
    fontFamily: 'inter',
    borderRadius: '32px',
    textTransform: 'none',
    '&:hover': {
      color: '#fff',
      boxShadow: 'none',
    },
  },
  scrollableContainer: {
    [theme.breakpoints.down('sm')]: {
      whiteSpace: 'nowrap',
      maxHeight: 100,
      overflow: 'auto',
    },
  },
  selectBoxTop: {
    width: '100%',
    fontFamily: 'inter',
    borderRadius: '22px',
  },
  listbox: {
    maxHeight: 200,
    overflow: 'auto',
    fontSize: 16,
  },
  cellphoneHeader: {
    color: '#000',
    fontFamily: 'Inter',
    fontSize: 13,
    fontStyle: 'italic',
    fontWeight: 500,
    lineHeight: '22px',
  },
  cellphone: {
    color: '#000',
    fontFamily: 'Inter',
    fontSize: 13,
    fontStyle: 'italic',
    fontWeight: 600,
    lineHeight: '22px',
    textDecoration: 'underline',
  },
  checked: {
    color: '#005959',
  },
  inputfieldfocused: {
    '& .css-o943dk-MuiFormLabel-root-MuiInputLabel-root.Mui-focused': {
      color: '#005959',
    },
  },
}));

const CustomAutoComplete = styled(Autocomplete)(() => ({
  '& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input': {
    fontSize: '14px',
  },
  '& .css-1q60rmi-MuiAutocomplete-endAdornment': {
    top: 'auto',
  },

  '& .MuiOutlinedInput-root': {
    borderRadius: 8,
    height: 48,
  },

  '& .MuiSelect-select.MuiSelect-select': {
    height: '100%',
    fontWeight: 500,
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Inter',
    backgroundColor: '#fff',
  },
}));

interface Service {
  service_type: string;
  diagnostic_problems: string[];
  diagnostic_problem_description: string;
  repair_problems: string[];
}

interface Car {
  id: number;
  year: number | undefined;
  make: string;
  model: string;
  engine_size: string;
  vin: string;
}

interface StepProps {
  nextStep: () => void;
  prevStep: () => void;
  serviceDetails: Service;
  carDetails: Car | undefined;
  setServiceDetails: (service: Service) => void;
}

const ServiceSelectionStep: React.FC<StepProps> = ({
  nextStep,
  prevStep,
  serviceDetails,
  setServiceDetails,
  carDetails,
}) => {
  const user = useSelector((state: IReduxState) => state.auth.user);

  const [valueEx, setValue] = useState(0);
  const [selectedOptionsDiagnoses, setSelectedOptionsDiagnoses] = useState<
    string[]
  >(serviceDetails?.diagnostic_problems);

  const [selectedUniqueOptionsDiagnoses, setSelectedUniqueOptionsDiagnoses] =
    useState<string[]>(serviceDetails?.diagnostic_problems);

  const [selectedUniqueOptionsRepair, setSelectedUniqueOptionsRepair] =
    useState<string[]>(serviceDetails?.repair_problems);

  const [selectedOptionsRepair, setSelectedOptionsRepair] = useState<string[]>(
    serviceDetails?.repair_problems
  );

  const [selectedServiceType, setSelectedServiceType] = useState<string>(
    serviceDetails?.service_type
  );

  const [describedProblem, setDescribedProblem] = useState<string>(
    serviceDetails?.diagnostic_problem_description
  );

  const [isObtainedDiagnoses, setIsObtainedDiagnoses] =
    useState<boolean>(false);
  const [isObtainedRepair, setIsObtainedRepair] = useState<boolean>(false);

  const [diagnosisContinueClicked, setDiagnosisContinueClicked] =
    useState<boolean>(false);

  const classes = useStyles();

  const handleChange = (
    event: React.ChangeEvent<unknown>,
    newValue: number
  ) => {
    setValue(newValue);
  };

  const handleChipAddDiagnoses = (option: string) => {
    if (selectedOptionsDiagnoses?.includes(option)) {
      return;
    }
    setSelectedOptionsDiagnoses((prevSelectedOptions = []) => [
      ...prevSelectedOptions,
      option,
    ]);
    setSelectedServiceType('diagnosis');
    setSelectedUniqueOptionsRepair([]);
  };

  const handleChipAddRepair = (option: string) => {
    if (selectedOptionsRepair?.includes(option) && option !== '') {
      return;
    }

    setSelectedOptionsRepair((prevSelectedOptions = []) => [
      ...prevSelectedOptions,
      option,
    ]);
    setSelectedServiceType('repair');
    setDescribedProblem('');
    setSelectedUniqueOptionsDiagnoses([]);
  };

  const handleChipDeleteDiagnoses = (option: string) => {
    setSelectedOptionsDiagnoses((prevSelectedOptions) =>
      prevSelectedOptions?.filter((selectedOption) => selectedOption !== option)
    );
  };

  const handleChipDeleteRepair = (option: string) => {
    setSelectedOptionsRepair((prevSelectedOptions) =>
      prevSelectedOptions?.filter((selectedOption) => selectedOption !== option)
    );
  };

  const handleTextboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedServiceType('diagnosis');
    setDescribedProblem(event.target.value);
  };

  useEffect(() => {
    const diagnosisStatus = describedProblem?.trim() !== '';
    setIsObtainedDiagnoses(diagnosisStatus);
  }, [describedProblem]);

  const dispatch = useDispatch();

  const [referralCode, setReferralCode] = useState<string>('');

  const serviceOptions = allStaticServices?.map((service) => service.name);

  const handleContinueDiagnoses = () => {
    setDiagnosisContinueClicked(true);
    setServiceDetails({
      service_type: selectedServiceType,
      diagnostic_problems: selectedUniqueOptionsDiagnoses,
      diagnostic_problem_description: describedProblem,
      repair_problems: selectedUniqueOptionsRepair,
    });

    if (
      carDetails?.year &&
      carDetails?.make &&
      carDetails?.model &&
      carDetails?.engine_size
    ) {
      const dataset: RequestCreateAppointment = {
        user_id: user?.id,
        kind: 'RequestCreateAppointment',
        appointment_type: selectedServiceType,
        car_attributes: {
          year: carDetails?.year?.toString(),
          make: carDetails?.make,
          model: carDetails?.model,
          engine_size: carDetails?.engine_size,
        },
        car_id: carDetails?.id,
        tracking_attributes: {
          utm_source: '',
          utm_medium: '',
          utm_term: '',
          utm_content: '',
          utm_campaign: '',
          gclid: '',
          client_id: '',
          fbp: '',
          fbc: '',
          fbclid: '',
          msclkid: '',
          path: '',
          coupon: '',
          adg: '',
          ref: '',
        },
        location_attributes: {
          zip: user?.attributes?.zip_code || '',
        },
        address: user?.attributes?.zip_code || '',
        diagnosis_input:
          describedProblem && describedProblem !== ''
            ? `${describedProblem}. ${selectedUniqueOptionsDiagnoses?.join(
                ', '
              )}`
            : `${selectedUniqueOptionsDiagnoses?.join(', ')}`,
        services: selectedOptionsRepair,
        level: serviceDetails?.service_type === 'diagnosis' ? 'A' : 'B',
        name: user?.attributes?.name,
        email: user?.attributes?.email,
        phone: user?.attributes?.phone,
        heard_about_us: '',
        referral_code: referralCode || '',
      };
      createFleetAppointment(dataset).then((res) => {
        setDiagnosisContinueClicked(false);
        dispatch(setAppointment(res.data));
        mixPanel('Fleet Service Selected', user?.id);
        nextStep();
      });
    }
  };

  useEffect(() => {
    setSelectedUniqueOptionsDiagnoses(
      Array.from(new Set(selectedOptionsDiagnoses))
    );
  }, [selectedOptionsDiagnoses]);

  useEffect(() => {
    setSelectedUniqueOptionsRepair(Array.from(new Set(selectedOptionsRepair)));
  }, [selectedOptionsRepair]);

  useEffect(() => {
    if (
      valueEx === 0 &&
      selectedOptionsDiagnoses &&
      selectedOptionsDiagnoses.length >= 1
    ) {
      setIsObtainedDiagnoses(true);
    } else {
      setIsObtainedDiagnoses(false);
    }
  }, [valueEx, selectedOptionsDiagnoses]);

  useEffect(() => {
    if (
      valueEx === 1 &&
      selectedUniqueOptionsRepair &&
      selectedUniqueOptionsRepair.length >= 1
    ) {
      setIsObtainedRepair(true);
    } else {
      setIsObtainedRepair(false);
    }
  }, [valueEx, selectedUniqueOptionsRepair]);

  const phoneNumber = '469-840-3990';
  const handleCall = () => {
    window.location.href = `tel:${phoneNumber.replace('-', '')}`;
  };

  return (
    <>
      <Card
        style={{
          background: '#fff',
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
        elevation={0}
      >
        <CardContent
          style={{
            background: '#fff',
            margin: 'auto',
            marginTop: 20,
            marginBottom: 20,
          }}
        >
          <Typography style={{ fontFamily: 'Inter', fontWeight: 600 }}>
            Select Services
          </Typography>

          <Tabs
            value={valueEx}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            style={{ width: '90%' }}
          >
            <Tab label="Diagnosis" className={classes.fontSmall} />
            <Tab label="Repair or Service" className={classes.fontSmall} />
          </Tabs>

          {valueEx === 0 && (
            <div>
              <TextField
                value={describedProblem}
                variant="outlined"
                style={{
                  marginTop: 20,
                  borderRadius: '8px',
                  height: 48,
                }}
                placeholder="Describe the problem you are facing"
                fullWidth
                multiline
                maxRows={3}
                rows={3}
                onChange={handleTextboxChange}
              />

              {selectedUniqueOptionsDiagnoses &&
                selectedUniqueOptionsDiagnoses.length >= 1 && (
                  <div className={classes.selectedChips}>
                    {selectedUniqueOptionsDiagnoses.map((option) => (
                      <Chip
                        key={option}
                        label={option}
                        onDelete={() => handleChipDeleteDiagnoses(option)}
                        deleteIcon={<Cancel className={classes.cancelIcon} />}
                        className={classes.chipSelected}
                      />
                    ))}
                  </div>
                )}

              <div
                style={{
                  marginTop:
                    selectedUniqueOptionsDiagnoses &&
                    selectedUniqueOptionsDiagnoses.length >= 1
                      ? 15
                      : 75,
                  marginBottom: 10,
                  overflowX: 'scroll',
                }}
              >
                {quoteOptions.map((option) => (
                  <Chip
                    key={option}
                    label={option}
                    onClick={() => handleChipAddDiagnoses(option)}
                    onDelete={() => handleChipAddDiagnoses(option)}
                    deleteIcon={<AddCircle className={classes.addIcon} />}
                    className={classes.chip}
                  />
                ))}
              </div>

              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Typography className={classes.cellphoneHeader}>
                  Can’t find what you are looking for?
                </Typography>

                <Typography
                  className={classes.cellphoneHeader}
                  style={{ marginLeft: 10 }}
                >
                  {' '}
                  Call Us:
                </Typography>
                <Typography
                  className={classes.cellphone}
                  onClick={handleCall}
                  style={{ cursor: 'pointer', marginLeft: 5 }}
                >
                  469-840-3990
                </Typography>
              </div>

              <TextField
                style={{ width: '100%', marginTop: 30 }}
                variant="filled"
                label="Coupon code (optional)"
                value={referralCode}
                className={classes.inputfieldfocused}
                InputProps={{
                  disableUnderline: true,
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      style={{ background: '#fff' }}
                    >
                      {referralCode !== '' && (
                        <CheckCircle className={classes.checked} />
                      )}
                    </InputAdornment>
                  ),
                  style: {
                    borderRadius: '8px',
                    fontSize: 17,
                    border: '0.5px solid grey',
                    background: '#fff',
                    fontFamily: 'Inter',
                    fontWeight: 500,
                    maxWidth: 400,
                  },
                }}
                onChange={(e) => setReferralCode(e.target.value)}
                fullWidth
              />

              <Button
                onClick={prevStep}
                style={{
                  background: '#D4D5D5',
                  color: 'grey',
                  marginTop: '35px',
                  marginLeft: '2px',
                }}
                className={classes.buttonForward}
                variant="contained"
                size="large"
              >
                Back &nbsp;
              </Button>
              <Button
                disabled={!isObtainedDiagnoses || diagnosisContinueClicked}
                onClick={handleContinueDiagnoses}
                style={{
                  background:
                    !isObtainedDiagnoses || diagnosisContinueClicked
                      ? '#D4D5D5'
                      : '#005959',
                  marginTop: '35px',
                  marginLeft: '2px',
                  color:
                    !isObtainedDiagnoses || diagnosisContinueClicked
                      ? '#000'
                      : '#fff',
                }}
                className={classes.buttonForward}
                variant="contained"
                size="large"
              >
                {diagnosisContinueClicked ? 'Loading...' : 'Continue'} &nbsp;
                <ArrowForward />
              </Button>
            </div>
          )}

          {valueEx === 1 && (
            <>
              <Typography className={classes.font} style={{ marginTop: 20 }}>
                Select your services
              </Typography>

              <CustomAutoComplete
                fullWidth
                classes={{ listbox: classes.listbox }}
                options={staticServiceList}
                style={{ marginTop: 10, width: '100%' }}
                disablePortal
                onChange={(event, newValue) => {
                  if (newValue) {
                    handleChipAddRepair(newValue as string);
                  }
                }}
                renderInput={(params) => (
                  <FormControl fullWidth>
                    <TextField
                      {...params}
                      placeholder="Search a Service"
                      variant="outlined"
                      className={classes.selectBoxTop}
                      fullWidth
                    />
                  </FormControl>
                )}
              />

              {selectedUniqueOptionsRepair &&
                selectedUniqueOptionsRepair.length >= 1 && (
                  <div className={classes.selectedChipsRepair}>
                    {selectedUniqueOptionsRepair.map((option) => (
                      <Chip
                        key={option}
                        label={option}
                        onDelete={() => handleChipDeleteRepair(option)}
                        deleteIcon={<Cancel className={classes.cancelIcon} />}
                        className={classes.chipSelected}
                      />
                    ))}
                  </div>
                )}
              <Typography
                className={classes.fontSmall}
                style={{ marginTop: 10 }}
              >
                Our Popular Services
              </Typography>
              <div style={{ marginTop: 15, marginBottom: 10 }}>
                {serviceOptions.map((option) => (
                  <Chip
                    key={option}
                    label={option}
                    onClick={() => handleChipAddRepair(option)}
                    onDelete={() => handleChipAddRepair(option)}
                    deleteIcon={<AddCircle className={classes.addIcon} />}
                    className={classes.chip}
                  />
                ))}
              </div>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Typography className={classes.cellphoneHeader}>
                  Can’t find what you are looking for?
                </Typography>

                <Typography
                  className={classes.cellphoneHeader}
                  style={{ marginLeft: 10 }}
                >
                  {' '}
                  Call Us:
                </Typography>
                <Typography
                  className={classes.cellphone}
                  onClick={handleCall}
                  style={{ cursor: 'pointer', marginLeft: 5 }}
                >
                  469-840-3990
                </Typography>
              </div>
              <TextField
                style={{ width: '100%', marginTop: 30 }}
                variant="filled"
                label="Coupon code (optional)"
                value={referralCode}
                className={classes.inputfieldfocused}
                InputProps={{
                  disableUnderline: true,
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      style={{ background: '#fff' }}
                    >
                      {referralCode !== '' && (
                        <CheckCircle className={classes.checked} />
                      )}
                    </InputAdornment>
                  ),
                  style: {
                    borderRadius: '8px',
                    fontSize: 17,
                    border: '0.5px solid grey',
                    background: '#fff',
                    fontFamily: 'Inter',
                    fontWeight: 500,
                    maxWidth: 400,
                  },
                }}
                onChange={(e) => setReferralCode(e.target.value)}
                fullWidth
              />

              <Button
                disabled={
                  (!isObtainedDiagnoses && !isObtainedRepair) ||
                  diagnosisContinueClicked
                }
                onClick={handleContinueDiagnoses}
                style={{
                  background:
                    !isObtainedDiagnoses && !isObtainedRepair
                      ? '#D4D5D5'
                      : '#005959',
                  marginTop: '35px',
                  marginLeft: '2px',
                  color:
                    !isObtainedDiagnoses && !isObtainedRepair ? '#000' : '#fff',
                }}
                className={classes.buttonForward}
                variant="contained"
                size="large"
              >
                {diagnosisContinueClicked ? 'Loading...' : 'Continue'} &nbsp;
                <ArrowForward />
              </Button>
            </>
          )}
        </CardContent>
      </Card>
    </>
  );
};

export default ServiceSelectionStep;
