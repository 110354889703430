import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  Typography,
} from '@material-ui/core';
import {
  ArrowBack,
  ArrowForward,
  ExpandMore,
  ToggleOff,
  ToggleOn,
} from '@material-ui/icons';
import giftbox from 'src/assets/giftbox.png';
import { IReduxState } from 'src/store/reducers';
import { updateEstimateServiceStatusV2 } from 'src/api/quote';
import mixPanel from 'src/utils/mixpanel';
import { setAppointment } from 'src/store/actions';
import QuoteSummary from 'src/views/Quote_New/components/atoms/QuoteSummary';
import SuccessStep from 'src/views/Quote_New/components/steps/SuccessStep';
import ReferralBox from 'src/views/Quote_New/components/atoms/ReferralCode';
import { ICoupon } from 'src/types';

interface StepProps {
  nextStep: () => void;
  prevStep: () => void;
  jobId?: string;
}

type ChildObject = {
  id: number;
  [key: string]: any;
};

type ParentObject = {
  [key: string]: ChildObject;
};

const EstimateStep: React.FC<StepProps> = ({ nextStep, prevStep, jobId }) => {
  const appointment = useSelector(
    (state: IReduxState) => state.quote.appointment
  );
  const dispatch = useDispatch();

  const handleDeleteRepairProblem = (id: number, statusUpdate: string) => {
    const data = { estimate_service: { status: statusUpdate } };
    updateEstimateServiceStatusV2(id, data).then((res) => {
      dispatch(setAppointment(res.data));
    });
  };

  function sortObjectChildrenById(obj: ParentObject): ParentObject {
    const sortedArray = Object.entries(obj)
      .map(([key, value]) => ({ key, ...value }))
      .sort((a, b) => a.id - b.id);
    const sortedObj: ParentObject = {};
    sortedArray.forEach((item) => {
      const { key, ...rest } = item;
      sortedObj[key] = rest;
    });

    return sortedObj;
  }

  const getCouponText = (coupon: ICoupon) => {
    if (coupon.code_type === 'agent') {
      let txt = 'Your service writer';

      if (coupon.display_name) {
        txt += `, ${coupon.display_name}, `;
      } else {
        txt += ' ';
      }

      txt += `applied a $${coupon.dollars_off} discount just for you.`;
      return txt;
    }

    return `${coupon.code} applied for ($${coupon.dollars_off} off)`;
  };

  return (
    <>
      {appointment?.attributes.appointment_type === 'repair' &&
      !appointment?.attributes.estimate ? (
        <SuccessStep />
      ) : (
        <Card
          style={{
            marginBottom: 10,
            borderRadius: 8,
            background: '#FFF',
            boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.06)',
          }}
          elevation={0}
        >
          <h1 style={{ marginLeft: 40 }}>Estimate</h1>

          <AccordionDetails
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {appointment?.attributes?.appointment_type === 'diagnosis' && (
              <Card
                style={{
                  background: '#fff',
                  marginLeft: 20,
                  marginRight: 20,
                  borderRadius: 8,
                  border: '1px solid #005959',
                  marginBottom: 20,
                  boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.06)',
                }}
                elevation={1}
              >
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Typography
                    style={{
                      fontFamily: 'Inter',
                    }}
                  >
                    Diagnosis
                  </Typography>
                </AccordionSummary>

                <Box style={{ margin: '10px' }}>
                  <Typography style={{ margin: '10px', fontFamily: 'Inter' }}>
                    - Our technician will come to you and find out what&apos;s
                    wrong.
                  </Typography>
                  <Typography style={{ margin: '10px', fontFamily: 'Inter' }}>
                    - Complimentary multi-point inspection.
                  </Typography>
                  <Typography style={{ margin: '10px', fontFamily: 'Inter' }}>
                    - A detailed estimate and repair plan will be provided.
                  </Typography>
                </Box>
              </Card>
            )}
            {appointment?.attributes.estimate && (
              <div
                style={{
                  maxHeight:
                    appointment?.attributes?.appointment_type === 'diagnosis'
                      ? '200px'
                      : '400px',
                  overflow: 'scroll',
                }}
              >
                {Object.keys(
                  sortObjectChildrenById(
                    appointment.attributes.estimate.services
                  )
                ).map((service, index) => (
                  <Accordion
                    key={service}
                    style={{
                      background: '#fff',
                      marginLeft: 20,
                      marginRight: 20,
                      borderRadius: 8,
                      border: '1px solid #005959',
                      marginBottom: 20,
                      boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.06)',
                    }}
                    elevation={1}
                  >
                    <AccordionSummary expandIcon={<ExpandMore />}>
                      <Typography
                        style={{
                          fontFamily: 'Inter',
                          textDecoration:
                            appointment.attributes.estimate?.services[service]
                              .status === 'rejected'
                              ? 'line-through'
                              : 'none',
                        }}
                      >
                        {service}
                      </Typography>
                    </AccordionSummary>
                    {appointment &&
                      appointment.attributes.estimate?.services[service]
                        .customer_to_supply_parts && (
                        <Typography
                          style={{
                            fontSize: 12,
                            color: 'grey',
                            fontFamily: 'Inter',
                            fontStyle: 'Italic',
                            marginLeft: 10,
                          }}
                        >
                          * Customer supplies parts
                        </Typography>
                      )}
                    <hr style={{ marginLeft: 10, marginRight: 10 }} />
                    <AccordionDetails>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        {appointment.attributes.estimate?.services[
                          service
                        ].parts.map((part) => {
                          return (
                            <div>
                              <Typography
                                style={{
                                  fontSize: 16,
                                  display: 'flex',
                                  flexDirection: 'row',
                                  fontFamily: 'Inter',
                                }}
                              >
                                {part.name} x({part.quantity}):&nbsp; $
                                {part.unit_price}{' '}
                              </Typography>
                            </div>
                          );
                        })}

                        <Typography
                          style={{
                            fontSize: 16,
                            display: 'flex',
                            flexDirection: 'row',
                            fontFamily: 'Inter',
                          }}
                        >
                          Labor :&nbsp; $
                          {
                            appointment.attributes.estimate?.services[service]
                              .labor_price
                          }{' '}
                        </Typography>
                      </div>
                      {appointment.attributes.estimate &&
                        Object.keys(appointment.attributes.estimate.services)
                          .length > 1 && (
                          <Button
                            onClick={() => {
                              if (appointment.attributes.estimate) {
                                const serviceToUpdate =
                                  appointment.attributes.estimate?.services[
                                    service
                                  ];

                                handleDeleteRepairProblem(
                                  serviceToUpdate.id,
                                  serviceToUpdate.status === 'rejected'
                                    ? 'approved'
                                    : 'rejected'
                                );
                              }
                            }}
                            style={{
                              marginLeft: 'auto',
                              color:
                                appointment.attributes.estimate?.services[
                                  service
                                ].status === 'rejected'
                                  ? 'red'
                                  : 'green',
                              padding: 0,
                            }}
                          >
                            {appointment.attributes.estimate?.services[service]
                              .status === 'rejected' ? (
                              <ToggleOff />
                            ) : (
                              <ToggleOn />
                            )}
                          </Button>
                        )}
                    </AccordionDetails>
                  </Accordion>
                ))}
              </div>
            )}
            <QuoteSummary fullWidth nonMember />
            {appointment?.attributes.coupon_used && (
              <div
                style={{
                  background: '#E3F2F9',
                  border: '1px solid #48ADD5',
                  display: 'flex',
                  maxWidth: 400,
                  gap: 20,
                  padding: '10px 20px',
                  borderRadius: 12,
                  margin: 20,
                  alignItems: 'center',
                }}
              >
                <img
                  src={giftbox}
                  alt="Credit Card"
                  style={{ width: 20, height: 20 }}
                />

                <div style={{ display: 'flex' }}>
                  <Typography
                    style={{
                      fontSize: 16,
                      color: '#1A5B74',
                      fontFamily: 'Inter',
                      fontWeight: 500,
                    }}
                  >
                    {getCouponText(appointment.attributes.coupon_used)}
                  </Typography>
                </div>
              </div>
            )}
            {!appointment?.attributes?.coupon_used && (
              <ReferralBox bgColor="#fff" />
            )}
            <div style={{ display: 'flex' }}>
              {' '}
              {!jobId && (
                <Button
                  onClick={prevStep}
                  style={{
                    background: '#D4D5D5',

                    margin: '0px 20px 20px 20px',
                    marginTop: '35px',
                    color: '#646867',
                    borderRadius: '32px',
                    fontFamily: 'inter',
                    textTransform: 'none',
                  }}
                  variant="contained"
                  size="large"
                >
                  <ArrowBack />
                  &nbsp; Back
                </Button>
              )}
              <Button
                onClick={() => {
                  mixPanel(
                    'Fleet Estimate',
                    `${appointment?.attributes?.user_id}`
                  );
                  nextStep();
                }}
                style={{
                  color: '#fff',
                  margin: '0px 20px 20px 20px',
                  padding: '6px 24px',
                  fontFamily: 'inter',
                  borderRadius: '32px',
                  textTransform: 'none',
                  background: '#005959',
                  marginTop: '35px',
                  maxWidth: 180,
                }}
                variant="contained"
                size="large"
              >
                Continue &nbsp;
                <ArrowForward />
              </Button>
            </div>
          </AccordionDetails>
        </Card>
      )}
    </>
  );
};

EstimateStep.defaultProps = {
  jobId: undefined,
};

export default EstimateStep;
