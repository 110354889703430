import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Autocomplete } from '@material-ui/lab';
import {
  Box,
  FormHelperText,
  Tab,
  Tabs,
  TextField,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { Info } from '@material-ui/icons';
import { ButtonForward } from 'src/components/atoms';
import { quoteOptions, staticServiceList } from 'src/utils/data';
import ModalBase from 'src/views/Dashboard/components/Modals/ModalBase';
import ServiceGallery from './ServiceGallery';
import ServiceSummary from './ServiceSummary';
import { QuoteContext } from '../../QuoteContext';
import ModalReasonOther from '../Modals/ModalNotSure/ModalReasonOther';

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

interface ServiceDeskProps {
  className?: string;
  onContinue?: (x: string) => void;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  popular: {
    fontSize: 20,
    marginTop: 20,
    paddingLeft: 10,
    fontWeight: 700,
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
    },
  },
  title: {
    fontSize: 23,
    lineHeight: '28px',
    fontWeight: 800,
    marginBottom: theme.spacing(3),
    width: '100%',
  },
  summaryContainer: {
    float: 'right',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  summaryService: {
    marginBottom: theme.spacing(2),
    width: '100%',
  },
  actionContainer: {
    [theme.breakpoints.down('xs')]: {
      position: 'fixed',
      bottom: '30px',
      right: '6%',
    },
    width: '100%',
    minHeight: 70,
    display: 'flex',
    alignItems: 'end',
    justifyContent: 'flex-end',

    '& .button-mobile-info': {
      width: 45,
      height: 45,
    },
  },
  buttonContinue: {
    padding: '12px 30px',
    maxWidth: 150,
    float: 'right',
  },
  helpBox: {
    border: '1px solid #ECECEC',
    padding: 20,
    backgroundColor: '#fff',
    borderRadius: 10,
    maxWidth: 200,
  },
  callHelp: {
    paddingTop: 5,
    paddingRight: 8,
    fontSize: 18,
    fontWeight: 800,
  },
  iconArrow: {
    marginTop: 4,
    height: 18,
    color: '#2A2D3C',
    cursor: 'pointer',
  },
  findText: {
    color: 'black',
    textDecoration: 'none',
  },
  autoComplete: {
    marginTop: '20px',
    background: '#fff',
  },
  autoCompleteInput: {
    '& .MuiOutlinedInput-input': {
      border: 'none',
    },
  },
  tabButton: {
    background: '#fff',
  },
  modalExplain: {
    padding: '10px',
  },
}));

const ServiceDesk = (props: ServiceDeskProps): ReactElement => {
  const { className, onContinue } = props;
  const [open, setOpen] = useState<boolean>(false);
  const {
    services,
    reason,
    handleSetReason,
    handleSetServices,
    serviceDeskTab,
    setServiceDeskTab,
  } = useContext(QuoteContext);
  const media = useMediaQuery('(max-width:360px)');
  const classes = useStyles();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const nextOilChange = query.get('service') === 'oil';

  useEffect(() => {
    if (nextOilChange) {
      handleSetServices(['Oil Change']);
      setServiceDeskTab(1);
    }
  }, [nextOilChange, handleSetServices, setServiceDeskTab]);

  const handleChange = (event: React.ChangeEvent<any>, newValue: number) => {
    if (newValue === 1) {
      handleSetReason({
        ...reason,
        otherReason: '',
        note: '',
      });
    } else {
      handleSetServices([]);
    }
    setServiceDeskTab(newValue);
  };

  const handleContinue = () => {
    if (onContinue) {
      const serviceType: string = serviceDeskTab === 0 ? 'diagnosis' : 'repair';

      if (serviceType === 'diagnosis') {
        handleSetServices([]);
      } else {
        handleSetReason({
          ...reason,
          otherReason: '',
          note: '',
        });
      }

      onContinue(serviceType);
    }
  };

  const handleOtherReasonChange = (newOtherReason: string) => {
    handleSetReason({
      ...reason,
      otherReason: newOtherReason,
      note: '',
    });
  };

  const handleDiagOptionSelected = (option: string) => {
    let newReason;

    if (reason.otherReason.includes(option)) {
      newReason = reason.otherReason.replace(`${option}.`, '');
    } else {
      newReason =
        reason.otherReason === ''
          ? `${option}.`
          : `${reason.otherReason} ${option}.`;
    }

    handleSetReason({
      ...reason,
      otherReason: newReason,
      note: '',
    });
  };

  const handleSelectService = (all: string[]) => {
    if (all.length === 0) {
      handleSetServices([]);
    } else {
      all.forEach((s) => {
        if (services.includes(s)) {
          handleSetServices(services.filter((x) => x !== s));
        } else {
          handleSetServices([...services, s]);
        }
      });
    }
  };

  return (
    <Box className={clsx('quote-choose-service', classes.root, className)}>
      <ModalBase
        open={open}
        onClose={() => setOpen(false)}
        className={classes.modalExplain}
      >
        <Box style={{ textAlign: 'center' }}>
          <Typography className={classes.popular}>Save Time & Money</Typography>
          <Typography style={{ margin: '10px' }}>
            Unless a professional technician has diagnosed your vehicle, you may
            be spending money on unnecessary repairs. Having one of our trained
            professionals perform a full diagnostic procedure ensures an
            accurate repair - saving you time, and money.
          </Typography>
        </Box>
      </ModalBase>
      <Box style={{ marginBottom: '80px' }}>
        <Typography className={classes.title}>How can we help?</Typography>
        <FormHelperText style={{ marginBottom: '10px' }}>
          Choose an option
        </FormHelperText>

        <Box>
          <Tabs
            value={serviceDeskTab}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab
              className={classes.tabButton}
              label="Diagnose my car"
              {...a11yProps(0)}
            />
            <Tab
              className={classes.tabButton}
              label="Pick my service"
              {...a11yProps(1)}
            />
          </Tabs>
        </Box>
        <TabPanel value={serviceDeskTab} index={0}>
          <Box style={{ flex: 1 }}>
            <Box
              style={{
                display: 'block',
                marginTop: '50px',
                // textAlign: 'center',
                minHeight: 200,
                maxWidth: '100%',
              }}
            >
              <Typography style={{ paddingBottom: '10px' }}>
                Not sure what&apos;s wrong? We&apos;ll send a diagnostic
                technician to you to figure out the issue.
              </Typography>

              <>
                <ModalReasonOther
                  otherReason={reason.otherReason}
                  onOtherReasonChange={handleOtherReasonChange}
                />
              </>
              <Box
                display="flex"
                flexWrap="wrap"
                py={2}
                gridColumnGap={5}
                gridRowGap={5}
              >
                {quoteOptions.map((item) => (
                  <Box
                    alignItems="center"
                    style={{
                      cursor: 'pointer',
                      border: reason.otherReason.includes(item)
                        ? '1px solid #005959'
                        : '',
                    }}
                    display="flex"
                    width="fit-content"
                    px={2}
                    borderRadius={32}
                    gridColumnGap={5}
                    py={1}
                  >
                    <AddIcon style={{ color: '#A1D5EA' }} />
                    <Typography
                      onClick={() => handleDiagOptionSelected(item)}
                      style={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        width: `${media ? '200px' : 'auto'}`,
                      }}
                    >
                      {item}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>
        </TabPanel>
        <TabPanel value={serviceDeskTab} index={1}>
          <Autocomplete
            className={classes.autoComplete}
            multiple
            options={staticServiceList}
            renderInput={(params) => (
              <TextField
                className={classes.autoCompleteInput}
                {...params}
                variant="outlined"
                placeholder="Search services"
              />
            )}
            onChange={(_, newValue) => {
              handleSelectService(newValue);
            }}
            value={services}
          />
          <Typography className={classes.popular}>
            <Info
              onClick={() => setOpen(true)}
              style={{
                color: '#005959',
                marginTop: '2px',
                fontSize: '18px',
                cursor: 'pointer',
              }}
            />{' '}
            Our Popular Services
          </Typography>
          <ServiceGallery />
          <ServiceSummary />
        </TabPanel>
      </Box>
      <Box className={classes.actionContainer}>
        <ButtonForward
          disabled={
            services.length === 0 &&
            reason.otherReason.replace(/\s+/g, '') === ''
          }
          title="Continue"
          rounded
          size="large"
          onClickHandler={handleContinue}
          className={classes.buttonContinue}
        />
      </Box>
    </Box>
  );
};

ServiceDesk.defaultProps = {
  className: undefined,
  onContinue: undefined,
};

export default ServiceDesk;
