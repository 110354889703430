import React, { ReactElement, useState } from 'react';
import { useSelector } from 'react-redux';

import { Button, Card, Typography } from '@material-ui/core';

import VisaCard from 'src/assets/payment/visa.svg';
import { EditOutlined } from '@material-ui/icons';
import { IReduxState } from 'src/store/reducers';

import ModalUpdateCC from './UpdateCC';

export default function CardsOnFile(): ReactElement {
  const user = useSelector((state: IReduxState) => state.auth.user);

  const [openCreditModal, setOpenCreditModal] = useState<boolean>(false);

  return (
    <Card
      style={{ width: '100%', padding: 30, background: '#fff' }}
      elevation={0}
    >
      <Typography style={{ fontFamily: 'Inter', marginBottom: 40 }}>
        Saved Payment Information
      </Typography>

      <Card
        style={{
          maxWidth: 'fit-content',
          padding: 20,
          display: 'flex',
          gap: 36,
        }}
      >
        {user?.attributes.card_on_file && (
          <>
            {user?.attributes.card_on_file?.brand === 'Visa' && (
              <img src={VisaCard} alt="Credit Card" style={{ width: 50 }} />
            )}

            <div style={{ alignSelf: 'flex-start' }}>
              <Typography style={{ fontFamily: 'Inter' }}>
                {user.attributes.card_on_file.brand} ending in{' '}
                {user.attributes.card_on_file.last4}
              </Typography>
              <Typography style={{ fontFamily: 'Inter' }}>
                Expires {user.attributes.card_on_file.exp_month}/
                {user.attributes.card_on_file.exp_year}
              </Typography>
            </div>
          </>
        )}

        <ModalUpdateCC
          show={openCreditModal}
          onClose={() => {
            setOpenCreditModal(false);
          }}
        />
        <Button
          onClick={() => {
            setOpenCreditModal(true);
          }}
          style={{ alignSelf: 'flex-start' }}
        >
          <EditOutlined
            style={{ marginRight: 10, color: '#005959', height: '25px' }}
          />

          <Typography
            style={{
              fontFamily: 'Inter',
              textTransform: 'none',
              color: '#005959',
              fontSize: 16,
            }}
          >
            {user?.attributes.card_on_file ? 'Edit' : 'Add Card'}
          </Typography>
        </Button>
      </Card>
    </Card>
  );
}
