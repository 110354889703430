import React, { useState, useEffect, useContext } from 'react';
import {
  Card,
  CardContent,
  Button,
  makeStyles,
  FormControl,
  Select,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
} from '@material-ui/core';
import { styled } from '@mui/material/styles';
import { checkOutOfCoverage, getVehicles } from 'src/api/quote';
import { CheckCircle, ArrowForward } from '@material-ui/icons';
import Typography from '@mui/material/Typography';
import mixPanel from 'src/utils/mixpanel';
import { MIXPANEL_TRACK_FUNNEL_V2 } from 'src/utils/consts';
import MenuItem from '@mui/material/MenuItem';
import { ButtonForward } from 'src/components/atoms';
import { QuoteConfirmedContext } from './Context/QuoteConfirmedContext';
import repairImage from '../../../../assets/repair.png';

interface CarDetailsStepProps {
  onNext: () => void;
  onComplete: () => void;
  activeStep: number;
  setStepUpdated: React.Dispatch<React.SetStateAction<number>>;
  car?: {
    year: string;
    make: string;
    model: string;
    engine: string;
    vin: string;
  };
}

const useStyles = makeStyles(() => ({
  checked: {
    color: '#005959',
  },
  nameLabel: {
    paddingLeft: 30,
    color: '#1F2322',
    fontFamily: 'Inter',

    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '22px',
  },

  ZipLabel: {
    color: '#1F2322',
    fontFamily: 'Inter',
    fontSize: 12,
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '22px',
    paddingLeft: 12,
    paddingBottom: 5,
    marginBottom: 5,
  },
  selectBox: {
    margin: '0px 20px 20px 20px',
    width: '95%',
    fontFamily: 'inter',
    borderRadius: '8px',
    height: 2,
  },
  selectBox2: {
    margin: '0px 20px 20px 20px',
    width: '80%',
    fontFamily: 'inter',
    borderRadius: '8px',
    height: 2,
  },
  selectBoxTop: {
    margin: '0px 20px 20px 20px',
    width: '95%',
    fontFamily: 'inter',
    borderRadius: '8px',
  },
  menuPaper: {
    maxHeight: 300,
    marginTop: '45px',
    fontFamily: 'inter',
    position: 'fixed',
    background: '#fff',
  },
  selectBoxInner: {
    borderRadius: '22px',
  },
  font: {
    fontFamily: 'inter',
  },
  fontEm: {
    color: '#646867',
    fontFamily: 'Inter',
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '22px',
  },
  licenseBox: {
    margin: 20,
    marginTop: 20,
    borderRadius: 2,
    fontFamily: 'Inter',
  },
  descBox: {
    width: '72%',
    margin: 20,
    borderRadius: 2,
    '& .MuiOutlinedInput-root': {
      borderRadius: '5px',
    },
  },
  buttonForward: {
    color: '#fff',
    margin: '0px 20px 20px 20px',
    padding: '6px 24px',
    fontFamily: 'inter',
    borderRadius: '32px',
    textTransform: 'none',
    '&:hover': {
      color: '#fff',
      boxShadow: 'none',
    },
  },
  buttonForwardZip: {
    color: '#fff',
    fontFamily: 'inter',
    borderRadius: '32px',
    textTransform: 'none',
  },
  roundedInput: {
    borderRadius: '10px',
  },
  ButtonForwarded: {
    height: '100%',
    position: 'absolute',
    right: 0,
    top: 0,
    '& .MuiButton-endIcon': {
      marginTop: 5,
    },
  },

  buttonForwardLogin: {
    color: '#005959',
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: '6px 24px',
    fontFamily: 'inter',
    borderRadius: '32px',
    textTransform: 'none',
    background: '#E6EEEE',
    marginBottom: 20,
  },
  container: {
    position: 'relative',
    borderRadius: 31,
    height: '45px',
    background: '#ECE9F1',
    paddingLeft: '20px',
    marginBottom: 20,
  },
  inputZip: {
    height: '100%',
    maxWidth: '280px',
    color: '#646867',
    fontFamily: 'Inter',
    fontWeight: 500,
    fontSize: 20,
    background: 'transparent',
    border: 'none',
    '&:focus': {
      outline: 'none',
    },
  },

  listItemLink: {},
  buttonForwardVirtual: {
    background: '#005959',
    color: '#fff',
  },
  logoContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '20px 0',
  },
}));

const CustomFormField = styled(FormControl)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    height: '60px',
  },
  '& .MuiOutlinedInput-input': {
    padding: 0,
    paddingLeft: 20,

    border: 'none',
    color: 'none',
  },

  '& .MuiSelect-select.MuiSelect-select': {
    height: '100%',
    fontWeight: 500,
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Inter',
    backgroundColor: '#fff',
  },
  '& .MuiSelect-icon': {
    top: 'auto',
  },
}));

const CarDetailsStep: React.FC<CarDetailsStepProps> = ({
  onNext,
  onComplete,
  activeStep,
  setStepUpdated,
  car = { year: '', make: '', model: '', engine: '', vin: '' },
}) => {
  const classes = useStyles();
  const isSm = useMediaQuery('(max-width: 800px)');

  const [optionMake, setOptionMake] = useState<string[]>([]);

  const [optionModel, setOptionModel] = useState<string[]>([]);

  const [optionEngine, setOptionEngine] = useState<string[]>([]);

  const [presetCarUpdated, setPresetCarUpdated] = useState<boolean>(false);

  const { QuoteConfirmed, setQuoteConfirmed } = useContext(
    QuoteConfirmedContext
  );

  const [zipCode, setZipCode] = useState<string>(QuoteConfirmed.car.zipcode);
  const [operationalZipCode, setoperationalZipCode] = useState(true);

  const [isModalOpen, setIsModalOpen] = useState(
    QuoteConfirmed.car.zipcode === ''
  );

  const [selectedCarYear, setSelectedCarYear] = useState<string>(
    QuoteConfirmed.car.year
  );
  const [selectedCarMake, setSelectedCarMake] = useState<string>(
    QuoteConfirmed.car.make
  );
  const [selectedCarModel, setSelectedCarModel] = useState<string>(
    QuoteConfirmed.car.model
  );
  const [selectedCarEngine, setSelectedCarEngine] = useState<string>(
    QuoteConfirmed.car.engine
  );

  const [isCarDataObtainedModel, setIsCarDataObtainedModel] =
    useState<boolean>(false);

  const oldestYear = 1989;
  const currentYear = new Date().getFullYear();
  const optionYear = Array(currentYear - oldestYear)
    .fill(0)
    .reduce((obj, x, index) => {
      return {
        ...obj,
        [currentYear - index]: currentYear - index,
      };
    }, {});

  const handleZipCodeSubmit = async () => {
    const modifiedObject = {
      ...QuoteConfirmed,
      car: {
        ...QuoteConfirmed.car,

        zipcode: zipCode,
      },
    };
    const resp = await checkOutOfCoverage(zipCode);

    if (resp.out_of_range) {
      setoperationalZipCode(false);
    } else {
      setQuoteConfirmed(modifiedObject);
      setIsModalOpen(false);
      mixPanel(MIXPANEL_TRACK_FUNNEL_V2.NF_ZIP);
    }
  };

  const handleChangeYear = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedCarYear(event.target.value as string);
    setSelectedCarMake('');
    setSelectedCarModel('');
    setSelectedCarEngine('');
  };

  const handleChangeMake = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedCarMake(event.target.value as string);
    setSelectedCarModel('');
    setSelectedCarEngine('');
  };

  const handleChangeModel = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedCarModel(event.target.value as string);
    setSelectedCarEngine('');
  };

  const handleChangeEngine = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedCarEngine(event.target.value as string);

    onComplete();
  };

  useEffect(() => {
    if (
      !presetCarUpdated &&
      car.year !== '' &&
      car.make !== '' &&
      car.model !== '' &&
      car.engine !== ''
    ) {
      setSelectedCarYear(car.year);
      setSelectedCarMake(car.make);
      setSelectedCarModel(car.model);
      setSelectedCarEngine(car.engine);

      const modifiedObject = {
        ...QuoteConfirmed,
        car: {
          ...QuoteConfirmed.car,
          selected_type_id: 0,
          selected_type: 'model',
          year: car.year,
          make: car.make,
          model: car.model,
          engine: car.engine,
          vin: car.vin,
        },
      };

      setQuoteConfirmed(modifiedObject);

      setPresetCarUpdated(true);

      if (activeStep === 0) {
        onNext();
      } else {
        setStepUpdated((prevStep) => prevStep + 1);
      }
    }
  }, [
    car,
    setQuoteConfirmed,
    QuoteConfirmed,
    presetCarUpdated,
    activeStep,
    onNext,
    setStepUpdated,
  ]);

  useEffect(() => {
    if (
      selectedCarYear !== '' &&
      selectedCarMake !== '' &&
      selectedCarModel !== '' &&
      selectedCarEngine !== ''
    ) {
      setIsCarDataObtainedModel(true);
    } else {
      setIsCarDataObtainedModel(false);
    }
  }, [selectedCarMake, selectedCarModel, selectedCarEngine, selectedCarYear]);

  useEffect(() => {
    const fetchData = async () => {
      const resp = await getVehicles(selectedCarYear, undefined, undefined);

      const mainData = resp.data;
      const makeArray = Object.values(mainData).map(
        (item) => item?.attributes?.make
      );
      const uniqueMakeArray = makeArray.filter(
        (value, index, self) => self.indexOf(value) === index
      );

      setOptionMake(uniqueMakeArray);
    };

    fetchData();
  }, [selectedCarYear]);

  useEffect(() => {
    const fetchData = async () => {
      const resp = await getVehicles(
        selectedCarYear,
        selectedCarMake,
        undefined
      );

      const mainData = resp.data;
      const modelArray = Object.values(mainData).map(
        (item) => item?.attributes?.model
      );

      const uniqueModelArray = modelArray.filter(
        (value, index, self) => self.indexOf(value) === index
      );

      setOptionModel(uniqueModelArray);
    };

    fetchData();
  }, [selectedCarYear, selectedCarMake]);

  useEffect(() => {
    const fetchData = async () => {
      const resp = await getVehicles(
        selectedCarYear,
        selectedCarMake,
        selectedCarModel
      );

      const mainData = resp.data;
      const engineArray = Object.values(mainData).map(
        (item) => item?.attributes?.engine_size
      );

      const uniqueEngineArray = engineArray.filter(
        (value, index, self) => self.indexOf(value) === index
      );

      if (uniqueEngineArray.length === 1) {
        setSelectedCarEngine(uniqueEngineArray[0]);
      }
      setOptionEngine(uniqueEngineArray);
    };

    fetchData();
  }, [selectedCarYear, selectedCarMake, selectedCarModel, QuoteConfirmed]);

  const handleButtonClickModel = () => {
    const modifiedObject = {
      ...QuoteConfirmed,
      car: {
        ...QuoteConfirmed.car,
        selected_type_id: 0,
        selected_type: 'model',
        zipcode: zipCode,
        year: selectedCarYear,
        make: selectedCarMake,
        model: selectedCarModel,
        engine: selectedCarEngine,
      },
    };

    setQuoteConfirmed(modifiedObject);
    mixPanel(MIXPANEL_TRACK_FUNNEL_V2.NF_CAR);
    if (activeStep === 0) {
      onNext();
    } else {
      setStepUpdated((prevStep) => prevStep + 1);
    }
  };

  const handleKeyPress = (event: { key: string }) => {
    if (event.key === 'Enter' && zipCode.length >= 5) {
      handleZipCodeSubmit();
    }
  };

  const handleRepairPalButtonClick = () => {
    window.open('https://repairpal.com/goodhood', '_blank');
  };

  return (
    <div>
      <Dialog open={isModalOpen}>
        {operationalZipCode ? (
          <>
            <DialogTitle>Where is your vehicle located?</DialogTitle>
            <DialogContent>
              <Typography
                className={classes.ZipLabel}
                style={{ fontSize: isSm ? 12 : 14 }}
              >
                Zip Code
              </Typography>
              <div className={classes.container}>
                <input
                  type="text"
                  pattern="[0-9]{5}"
                  inputMode="numeric"
                  className={classes.inputZip}
                  placeholder="Enter Zip"
                  value={zipCode}
                  onChange={(e) => setZipCode(e.target.value)}
                  onKeyDown={handleKeyPress}
                />
                <ButtonForward
                  title="Continue"
                  rounded
                  size="medium"
                  className={classes.ButtonForwarded}
                  onClickHandler={handleZipCodeSubmit}
                  disabled={zipCode.length < 5}
                />
              </div>
            </DialogContent>
          </>
        ) : (
          <>
            <DialogContent>
              <div
                style={{
                  width: isSm ? '100%' : '500px',
                  height: isSm ? '320px' : '350px',
                  display: 'flex',
                  flexDirection: 'column',
                  marginTop: isSm ? '10px' : '20px',
                }}
              >
                <Typography
                  style={{
                    fontWeight: 'bold',
                    fontSize: isSm ? '16px' : '24px',
                    textAlign: 'center',
                  }}
                >
                  Sorry we`re not in your area just yet. Our partner RepairPal
                  can take care of you.
                </Typography>
                <div className={classes.logoContainer}>
                  <img
                    src={repairImage}
                    alt="RepairPal"
                    style={{
                      width: isSm ? '80px' : '100px',
                      marginRight: '10px',
                    }}
                  />
                </div>
                <Button
                  style={{
                    backgroundColor: '#005959',
                    color: '#fff',
                    border: 'none',
                    borderRadius: '32px',
                    padding: isSm ? '10px 20px' : '15px 20px',
                    textTransform: 'none',
                  }}
                  onClick={handleRepairPalButtonClick}
                >
                  <Typography
                    style={{
                      fontWeight: 'bold',
                      fontSize: isSm ? '14px' : '16px',
                      textAlign: 'center',
                    }}
                  >
                    Visit RepairPal
                  </Typography>
                </Button>
                <Typography
                  style={{
                    textAlign: 'center',
                    padding: '20px',
                    color: 'grey',
                    textDecoration: 'underline',
                    cursor: 'pointer',
                  }}
                  component="a"
                  onClick={(e) => {
                    setoperationalZipCode(true);
                  }}
                >
                  Try another zip code
                </Typography>
              </div>
            </DialogContent>
          </>
        )}
      </Dialog>

      <Card
        style={{
          background: '#fff',
          marginLeft: 'auto',
          marginRight: 'auto',
          width: isSm ? '95%' : 510,
        }}
        elevation={0}
      >
        <CardContent>
          <CustomFormField
            variant="outlined"
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              marginTop: 30,
            }}
          >
            <Typography
              className={classes.nameLabel}
              style={{ fontSize: isSm ? 12 : 14 }}
            >
              Year
            </Typography>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <Select
                value={selectedCarYear}
                onChange={handleChangeYear}
                placeholder="Select"
                className={classes.selectBoxTop}
                inputProps={{ 'aria-label': 'Without label' }}
                MenuProps={{
                  classes: { paper: classes.menuPaper },
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                  },
                }}
                displayEmpty
              >
                <MenuItem value="" className={classes.font}>
                  <em className={classes.fontEm}>Select</em>
                </MenuItem>
                {Object.keys(optionYear).map((year) => (
                  <MenuItem
                    style={{ padding: 10 }}
                    key={year}
                    value={optionYear[year]}
                    className={classes.font}
                  >
                    {year}
                  </MenuItem>
                ))}
              </Select>
              {selectedCarYear && (
                <CheckCircle
                  className={classes.checked}
                  style={{
                    margin: '8px',
                    marginLeft: '0px',
                    marginTop: '-5px',
                  }}
                />
              )}
            </div>
          </CustomFormField>

          <CustomFormField
            variant="outlined"
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              borderRadius: '22px',
            }}
          >
            <Typography
              className={classes.nameLabel}
              style={{ fontSize: isSm ? 12 : 14 }}
            >
              Make
            </Typography>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <Select
                value={selectedCarMake}
                onChange={handleChangeMake}
                className={classes.selectBox}
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}
                MenuProps={{
                  classes: { paper: classes.menuPaper },
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                  },
                }}
              >
                <MenuItem value="" className={classes.font}>
                  <em className={classes.fontEm}>Select</em>
                </MenuItem>
                {optionMake.map((make) => (
                  <MenuItem key={make} value={make} className={classes.font}>
                    {make}
                  </MenuItem>
                ))}
              </Select>
              {selectedCarMake && (
                <CheckCircle
                  className={classes.checked}
                  style={{
                    margin: '8px',
                    marginLeft: '0px',
                    marginTop: '-5px',
                  }}
                />
              )}
            </div>
          </CustomFormField>

          <CustomFormField
            variant="outlined"
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Typography
              className={classes.nameLabel}
              style={{ fontSize: isSm ? 12 : 14 }}
            >
              Model
            </Typography>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <Select
                value={selectedCarModel}
                onChange={handleChangeModel}
                className={classes.selectBox}
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}
                MenuProps={{ classes: { paper: classes.menuPaper } }}
                style={{ fontFamily: 'inter' }}
              >
                <MenuItem value="" className={classes.font}>
                  <em className={classes.fontEm}>Select</em>
                </MenuItem>
                {optionModel.map((model) => (
                  <MenuItem key={model} value={model} className={classes.font}>
                    {model}
                  </MenuItem>
                ))}
              </Select>
              {selectedCarModel !== '' && (
                <CheckCircle
                  className={classes.checked}
                  style={{
                    margin: '8px',
                    marginLeft: '0px',
                    marginTop: '-5px',
                  }}
                />
              )}
            </div>
          </CustomFormField>

          <CustomFormField
            variant="outlined"
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              marginBottom: '20px',
            }}
          >
            <Typography
              className={classes.nameLabel}
              style={{ fontSize: isSm ? 12 : 14 }}
            >
              Engine
            </Typography>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <Select
                value={selectedCarEngine}
                onChange={handleChangeEngine}
                className={classes.selectBox}
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}
                MenuProps={{ classes: { paper: classes.menuPaper } }}
              >
                <MenuItem value="" className={classes.font}>
                  <em className={classes.fontEm}>Select</em>
                </MenuItem>
                {optionEngine.map((engine) => (
                  <MenuItem
                    key={engine}
                    value={engine}
                    className={classes.font}
                  >
                    {engine}
                  </MenuItem>
                ))}
              </Select>
              {selectedCarEngine && (
                <CheckCircle
                  className={classes.checked}
                  style={{
                    margin: '8px',
                    marginLeft: '0px',
                    marginTop: '-5px',
                  }}
                />
              )}
            </div>
          </CustomFormField>

          <Button
            disabled={!isCarDataObtainedModel}
            onClick={handleButtonClickModel}
            style={{
              background: !isCarDataObtainedModel ? '#D4D5D5' : '#005959',
            }}
            className={classes.buttonForward}
            variant="contained"
            size="large"
          >
            Continue&nbsp;
            <ArrowForward />
          </Button>
        </CardContent>
      </Card>
    </div>
  );
};

CarDetailsStep.defaultProps = {
  car: { year: '', make: '', model: '', engine: '', vin: '' },
};

export default CarDetailsStep;
