import React, { ReactElement, useEffect, useState } from 'react';
import { IAppointment } from 'src/types';
import { getUserAppointments } from 'src/api/auth';
import { Link, Typography } from '@material-ui/core';
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
} from '@mui/x-data-grid';

export default function PastAppointments(): ReactElement {
  const [filtered, setFiltered] = useState<IAppointment[]>([]);
  const [requested, setRequested] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!requested) {
      setRequested(true);
      setLoading(true);

      getUserAppointments('completed').then((resp) => {
        setLoading(false);

        setFiltered(resp.data);
      });
    }
  }, [requested]);

  const serviceDisplay = (appt: IAppointment) => {
    let str = '';
    if (appt.attributes.appointment_type === 'diagnosis') {
      str += 'Diagnosis';
    }

    if (appt.attributes.estimate) {
      const { services } = appt.attributes.estimate;

      const strings: string[] = [];
      Object.keys(appt.attributes.estimate.services).forEach((key) => {
        if (
          services[key].status === 'approved' ||
          services[key].status === 'locked'
        ) {
          strings.push(`${key}`);
        }
      });

      if (strings.length > 0) {
        if (appt.attributes.appointment_type === 'diagnosis') {
          str += '. ';
        }
        str += 'Repairs: ';
        str += strings.join(', ');
      }
    }

    return str;
  };

  const rows = filtered.map((appt, index) => {
    const inspectionDoc = appt.attributes.documents.find(
      (doc) => doc.name === 'Inspection'
    );
    const diagReceipt = appt.attributes.documents.find(
      (doc) => doc.name === 'Diagnosis Receipt'
    );
    const repairReceipt = appt.attributes.documents.find(
      (doc) => doc.name === 'Repair Receipt'
    );

    return {
      id: index,
      service: serviceDisplay(appt),
      date: appt.attributes.appointment_day,
      vehicle: `${appt.attributes.car.year} ${appt.attributes.car.make} ${appt.attributes.car.model}`,
      vin: appt.attributes.car.vin,
      inspection: inspectionDoc,
      receipt: diagReceipt,
      repair_receipt: repairReceipt,
    };
  });

  const columns: GridColDef[] = [
    { field: 'service', headerName: 'Service', width: 450, filterable: true },
    { field: 'date', headerName: 'Date', width: 180 },
    { field: 'vehicle', headerName: 'Vehicle', width: 250 },
    { field: 'vin', headerName: 'VIN', width: 250 },
    {
      field: 'inspection',
      headerName: 'Inspection',
      width: 130,
      valueFormatter: (params) => params.value?.url || '',
      renderCell: (params: GridRenderCellParams) =>
        params.value && params.value.url ? (
          <Link
            href={params.value.url}
            target="_blank"
            rel="noreferrer noopener"
          >
            View
          </Link>
        ) : (
          <></>
        ),
    },
    {
      field: 'receipt',
      headerName: 'Diag Receipt',
      width: 130,
      valueFormatter: (params) => params.value?.url || '',
      renderCell: (params: GridRenderCellParams) =>
        params.value && params.value.url ? (
          <Link
            href={params.value.url}
            target="_blank"
            rel="noreferrer noopener"
          >
            View
          </Link>
        ) : (
          <></>
        ),
    },
    {
      field: 'repair_receipt',
      headerName: 'Repair Receipt',
      width: 130,
      valueFormatter: (params) => params.value?.url || '',
      renderCell: (params: GridRenderCellParams) =>
        params.value && params.value.url ? (
          <Link
            href={params.value.url}
            target="_blank"
            rel="noreferrer noopener"
          >
            View
          </Link>
        ) : (
          <></>
        ),
    },
  ];

  function CustomToolbar() {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          borderBottom: '0.1px solid lightgrey',
        }}
      >
        <Typography
          style={{ fontFamily: 'Inter', fontSize: '28px', margin: 10 }}
        >
          Past Services
        </Typography>
        <GridToolbarContainer>
          <GridToolbarFilterButton style={{ color: '#344054' }} />
          <GridToolbarExport
            style={{ color: '#344054' }}
            csvOptions={{ fileName: 'goodhood-services' }}
          />
        </GridToolbarContainer>
      </div>
    );
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },
        }}
        pageSizeOptions={[5, 10]}
        autoHeight
        style={{ width: '100%' }}
        loading={loading}
        components={{
          Toolbar: CustomToolbar,
        }}
      />
    </div>
  );
}
