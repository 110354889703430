import React, { ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { ICarFromList } from 'src/types';

import { Button, Typography } from '@material-ui/core';
import {
  DataGrid,
  GridColDef,
  GridToolbarContainer,
  GridToolbarFilterButton,
} from '@mui/x-data-grid';
import { Add, EditOutlined } from '@material-ui/icons';
import { IReduxState } from 'src/store/reducers';

import VehicleForm from './VehicleForm';

interface Car {
  year: number | undefined;
  make: string;
  model: string;
  engine_size: string;
  vin: string;
  name: string;
}

export default function Vehicles(): ReactElement {
  const [editingName, setEditingName] = useState<boolean>(false);
  const [editingId, setEditingId] = useState<number | undefined>();
  const [yearOptions, setYearOptions] = useState<string[] | undefined>();
  const [filtered, setFiltered] = useState<ICarFromList[]>();
  const [car, setCar] = useState<Car | undefined>();

  const user = useSelector((state: IReduxState) => state.auth.user);

  useEffect(() => {
    if (user?.attributes.total_unique_cars) {
      const years = user.attributes.total_unique_cars.map((i) => i.one_string);
      setYearOptions(Array.from(new Set(years)).sort());

      setFiltered(user.attributes.total_unique_cars);
    }
  }, [user]);

  const columns: GridColDef[] = [
    { field: 'vehicle', headerName: 'Vehicle', width: 350 },
    { field: 'engineSize', headerName: 'Engine Size', width: 330 },
    { field: 'vin', headerName: 'VIN', width: 330 },
    {
      field: 'name',
      headerName: 'Name',
      width: 330,
      renderCell: (params) => (
        <>
          <Typography
            style={{
              fontFamily: 'Inter',
              textTransform: 'none',
              color: '#005959',
              fontSize: 16,
            }}
          >
            {params.row.name}
          </Typography>
          <Button
            onClick={() => {
              setEditingName(true);
              setEditingId(params.row.id);
              setCar({
                year: params.row.year,
                make: params.row.make,
                model: params.row.model,
                engine_size: params.row.engineSize,
                vin: params.row.vin,
                name: params.row.name,
              });
            }}
          >
            <EditOutlined
              style={{ marginRight: 10, color: '#005959', height: '25px' }}
            />
            <Typography
              style={{
                fontFamily: 'Inter',
                textTransform: 'none',
                color: '#005959',
                fontSize: 16,
              }}
            >
              {' '}
              Edit Name
            </Typography>
          </Button>
        </>
      ),
    },
  ];

  filtered?.sort((a, b) => a.id - b.id);

  const rows =
    filtered?.map((vehicle) => ({
      id: vehicle.id,
      vehicle: `${vehicle.year} ${vehicle.make} ${vehicle.model}`,
      year: vehicle.year,
      make: vehicle.make,
      model: vehicle.model,
      engineSize: vehicle.engine_size,
      vin: vehicle.vin,
      name: vehicle.name,
    })) || [];

  function CustomToolbar() {
    const [openCreateCar, setOpenCreateCar] = useState<boolean>(false);
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          borderBottom: '0.1px solid lightgrey',
        }}
      >
        <Typography
          style={{ fontFamily: 'Inter', fontSize: '28px', margin: 10 }}
        >
          Vehicles
        </Typography>
        <GridToolbarContainer>
          <GridToolbarFilterButton style={{ color: '#344054' }} />
          <Button
            onClick={() => setOpenCreateCar(true)}
            style={{
              maxWidth: 'fit-content',
              marginRight: 10,
              marginLeft: 10,
              background: '#E6EEEE',
              color: '#005959',
              borderRadius: 8,
              padding: '6px 15px',
            }}
          >
            <Add style={{ marginRight: 10 }} />
            <Typography style={{ fontFamily: 'Inter', textTransform: 'none' }}>
              {' '}
              Add Vehicle
            </Typography>
          </Button>
          <VehicleForm
            open={openCreateCar}
            onClose={() => setOpenCreateCar(false)}
            type="create"
            carId={undefined}
            carDetails={undefined}
          />
        </GridToolbarContainer>
      </div>
    );
  }

  return (
    <div>
      <DataGrid
        rows={rows}
        columns={columns}
        components={{
          Toolbar: CustomToolbar,
        }}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },
        }}
        pageSizeOptions={[5, 10]}
        autoHeight
        style={{ width: '100%' }}
      />
      {car && (
        <VehicleForm
          open={editingName}
          onClose={() => setEditingName(false)}
          type={editingName ? 'update' : 'create'}
          carId={editingId}
          carDetails={car}
        />
      )}
    </div>
  );
}
