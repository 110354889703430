import React, { ReactElement, useState } from 'react';
import clsx from 'clsx';
import { makeStyles, TextField, Typography } from '@material-ui/core';
import { CheckCircle } from '@material-ui/icons';
import { createJobFeedback } from 'src/api/quote';
import ModalBase from '../../../../../Dashboard/components/Modals/ModalBase';
import { useFontStyles } from '../../../../../Dashboard/components/useFontStyles';
import BtnDashboard from '../../../../../Dashboard/components/DashboardButton';

interface JobFeedbackModalProps {
  id: number;
  className?: string;
  open: boolean;
  onClose: () => void;
  page: string;
  text: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    background: 'white',
    '& strong': {
      color: theme.palette.primary.main,
      fontWeight: 600,
    },
  },
  icon: {
    marginBlock: 0,
    fontSize: '72px',
  },
  header: {
    textAlign: 'center',
  },
  content: {
    paddingInline: '8px',
  },
  actions: {
    padding: '10px',
    display: 'flex',
    flexDirection: 'column',
    '& > button:first-child': {
      marginBottom: '10px',
    },
  },
  answer: {
    margin: '20px 0',
    overflow: 'hidden',
    '& .radio-box': {
      justifyContent: 'flex-start',
    },
  },
}));

export default function JobFeedbackModal(
  props: JobFeedbackModalProps
): ReactElement {
  const { id, className, open, onClose, page, text } = props;
  const classes = useStyles();
  const fontClasses = useFontStyles();
  const [feedback, setFeedback] = useState<string>('');
  const [success, setSuccess] = useState<boolean>(false);

  return (
    <ModalBase
      open={open}
      onClose={onClose}
      className={clsx(className, classes.root)}
    >
      <>
        <div className={classes.header}>
          <h2 className={fontClasses.modalHeader} style={{ marginBlock: 3 }}>
            What&apos;s on your mind?
          </h2>
        </div>
        <div className={classes.content}>
          <p>{text}</p>
        </div>
        <div className={classes.answer}>
          <TextField
            fullWidth
            multiline
            rows={4}
            variant="outlined"
            label="Type your feedback here"
            onChange={(e) => setFeedback(e.target.value)}
          />
        </div>
        <div style={{ margin: '10px 0' }}>
          {success && (
            <Typography color="primary">
              Thank you for your feedback, our team has been notified.
            </Typography>
          )}
        </div>
        <div className={classes.actions}>
          <BtnDashboard
            variant="primary"
            onClick={() => {
              createJobFeedback(id, feedback, page).then(() => {
                setSuccess(true);
                setTimeout(() => {
                  setSuccess(false);
                  onClose();
                }, 4000);
              });
            }}
            startIcon={<CheckCircle />}
          >
            Submit
          </BtnDashboard>
        </div>
      </>
    </ModalBase>
  );
}

JobFeedbackModal.defaultProps = {
  className: '',
};
