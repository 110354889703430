import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stack from '@mui/material/Stack';
import { Edit, Done } from '@material-ui/icons';
import {
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
  Avatar,
  useMediaQuery,
  useTheme,
  IconButton,
} from '@material-ui/core';
import { apiUpdateProfileInfo, getUser } from 'src/api/auth';

interface ProfileProps {
  userId: string | null | undefined;
  setUserName: (username: string) => void;
  setPhoneNumber: (phoneNumber: string) => void;
  name: string | null | undefined;
  address: string;
  phoneNumber: string | null | undefined;
}

const useStyles = makeStyles((theme) => ({
  profileCard: {
    backgroundColor: '#fff',
    borderRadius: '8px',
    fontFamily: 'Inter, sans-serif',
    position: 'relative',
  },
  profilePicture: {
    borderRadius: '50%',
    width: 70,
    height: 70,
    margin: 15,
    marginBottom: theme.spacing(2),
    fontSize: '32px',
    fontFamily: 'Inter',
  },
  profilePictureSmall: {
    borderRadius: '50%',
    width: 50,
    height: 50,
    margin: 12,
    marginBottom: theme.spacing(2),
    fontSize: '32px',
    fontFamily: 'Inter',
  },
  profileName: {
    fontSize: '24px',
    fontFamily: 'Inter',
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
  },
  profileAddress: {
    fontSize: '16px',
    fontFamily: 'Inter',
    marginBottom: theme.spacing(1),
  },
  profilePhone: {
    fontSize: '16px',
    fontFamily: 'Inter',
    marginBottom: theme.spacing(1),
  },
  editButton: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
    color: '#005959',
    borderRadius: '32px',
    fontWeight: 400,
    fontFamily: 'Inter',
    maxWidth: '300px',
    marginLeft: 'auto',
    background: '#E6EEEE',
    textTransform: 'none',
    '@media (max-width: 600px)': {
      margin: 0,
      width: '10%',
      padding: 4,
    },
    '&:hover': {
      background: '#E6EEEE',
      color: '#005959',
    },
  },
  saveButton: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
    color: '#005959',
    borderRadius: '32px',
    fontWeight: 400,
    fontFamily: 'Inter',
    maxWidth: '300px',
    marginLeft: 'auto',
    background: '#E6EEEE',
    textTransform: 'none',
    '@media (max-width: 600px)': {
      margin: 0,
      width: '10%',
      padding: 4,
    },
    '&:hover': {
      background: '#E6EEEE',
      color: '#005959',
    },
  },
  inputField: {
    marginBottom: theme.spacing(1),
  },
  inputFieldName: {
    marginBottom: theme.spacing(1),
  },
  title: {
    color: '#1F2322',
    fontSize: '20px',
    fontFamily: 'Inter',
    fontWeight: 700,
    marginBottom: theme.spacing(4),
  },
}));

const Profile: React.FC<ProfileProps> = ({
  setUserName,
  setPhoneNumber,
  userId,
  name,
  address,
  phoneNumber,
}) => {
  const classes = useStyles();
  const [isEditMode, setIsEditMode] = useState(false);
  const [editedName, setEditedName] = useState(name);
  const [editedAddress, setEditedAddress] = useState(address);
  const [editedPhoneNumber, setEditedPhoneNumber] = useState(phoneNumber);

  const toggleEditMode = () => {
    setIsEditMode(!isEditMode);
  };

  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down('sm'), {
    defaultMatches: true,
  });

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEditedName(event.target.value);
  };

  const handleAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEditedAddress(event.target.value);
  };

  const handlePhoneNumberChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setEditedPhoneNumber(event.target.value);
  };

  const handleEditDone = async () => {
    setIsEditMode(!isEditMode);
    if (!userId || !editedName || !editedPhoneNumber) return;
    await apiUpdateProfileInfo(userId, {
      name: editedName,
      phone: editedPhoneNumber,
    });
    const userData = await getUser();
    const { name: username, phone: phoneNum } = userData.data.attributes;
    setUserName(username);
    setPhoneNumber(phoneNum);
    // setAddress(userAddress)
  };

  return (
    <Card className={classes.profileCard} elevation={3}>
      <Stack direction="row">
        {isEditMode ? (
          <>
            <CardContent>
              <Typography className={classes.title} variant="h4">
                Edit Details
              </Typography>
              <TextField
                variant="filled"
                size="small"
                className={classes.inputFieldName}
                label="Name"
                value={editedName}
                onChange={handleNameChange}
                fullWidth
              />
              <TextField
                variant="filled"
                className={classes.inputField}
                label="Address"
                value={editedAddress}
                onChange={handleAddressChange}
                fullWidth
              />
              <TextField
                variant="filled"
                className={classes.inputField}
                label="Phone Number"
                value={editedPhoneNumber}
                onChange={handlePhoneNumberChange}
                fullWidth
              />
            </CardContent>
            {!isSm ? (
              <Button
                className={classes.saveButton}
                variant="contained"
                color="primary"
                onClick={handleEditDone}
                endIcon={<Done style={{ paddingTop: '2px' }} />}
              >
                Done
              </Button>
            ) : (
              <IconButton
                className={classes.editButton}
                onClick={handleEditDone}
                aria-label="delete"
              >
                <Done />
              </IconButton>
            )}
          </>
        ) : (
          <>
            {!isSm ? (
              <Avatar className={classes.profilePicture}>
                {name?.charAt(0)}
              </Avatar>
            ) : (
              <Avatar className={classes.profilePictureSmall}>
                {name?.charAt(0)}
              </Avatar>
            )}
            <CardContent>
              <Typography className={classes.profileName} variant="h4">
                {name}
              </Typography>
              <Typography className={classes.profileAddress}>
                {address}
              </Typography>
              <Typography className={classes.profilePhone}>
                {phoneNumber}
              </Typography>
            </CardContent>
            {!isSm ? (
              <Button
                className={classes.editButton}
                variant="contained"
                color="default"
                onClick={toggleEditMode}
                endIcon={<Edit />}
              >
                Edit
              </Button>
            ) : (
              <IconButton
                className={classes.editButton}
                onClick={toggleEditMode}
                aria-label="delete"
              >
                <Edit />
              </IconButton>
            )}
          </>
        )}
      </Stack>
    </Card>
  );
};

export default Profile;
