import React, {
  ReactElement,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import {
  Box,
  Typography,
  Container,
  FormHelperText,
  useTheme,
  useMediaQuery,
  Select,
  MenuItem,
  FormControl,
  Grid,
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import {
  GoogleAddressInputWithStatus,
  ButtonForward,
} from 'src/components/atoms';
import {
  AlarmAdd,
  DirectionsCar,
  EventAvailable,
  LocationOn,
} from '@material-ui/icons';

import moment from 'moment';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { QuoteContext } from 'src/views/Quote/QuoteContext';
import { Layout } from 'src/layouts';
import { ResponseAvailability, QuoteShowModal, IAppointment } from 'src/types';
import { IReduxState } from 'src/store/reducers';
import { checkAvailability, updateZipCode } from 'src/api/quote';
import mixPanel from 'src/utils/mixpanel';
import { MIXPANEL_TRACK } from 'src/utils/consts';
import MDatePicker from 'src/components/atoms/DatePicker';
import useStyles from './styles';
import fontStyles from '../../fontStyles';
import JobFeedbackModal from '../NewModalReviewQuote/components/JobFeedbackModal';

interface ModalScheduleServiceProps {
  show: boolean;
  onClose: () => void;
}

const ModalScheduleService = (
  props: ModalScheduleServiceProps
): ReactElement => {
  const { show, onClose } = props;
  const classes = useStyles();

  const locationRef = useRef<HTMLDivElement>(null);
  const timeSlotRef = useRef<HTMLDivElement>(null);

  const [locationErr, setLocationErr] = useState<string | null>();
  const [timeErr, setTimeErr] = useState<string | null>();

  const [feedbackOpen, setFeedbackOpen] = useState<boolean>(false);

  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (show) {
      setTimeout(() => {
        setFeedbackOpen(true);
      }, 90000);
    }
  }, [show]);

  const {
    handleUpdateAppointmentTime,
    handleRespondAppointmentEstimate,
    isMechanicEstimate,
    isOfficeEstimate,
    requestInProgress,
    handleShowModal,
  } = useContext(QuoteContext);

  const appointment: IAppointment | null = useSelector(
    (state: IReduxState) => state.quote.appointment
  );

  const appointmentId = appointment?.id;

  const appointmentStatus = appointment?.attributes?.status;

  const memberJob = appointment?.attributes.member_job;

  const [date, changeDate] = useState<MaterialUiPickersDate>(moment());

  const [timeSlots, setTimeSlots] = useState<{ [dt: string]: string[] }>({});

  const keyDate = (date && date.format('YYYY-MM-DD')) || '';

  const timeSlotsToday = (timeSlots && timeSlots[keyDate]) || [];

  const [selectedTimeSlotValue, setSelectedTimeSlotValue] = useState('');

  const [location, setLocation] = useState({
    type_of_site: '',
    exact_address: '',
    description: '',
  });

  const [errorWithZip, setErrorWithZip] = useState<boolean>(false);

  const updateZipCodeAsync = async (newZipCode: string) => {
    try {
      if (appointment) {
        const zipresponse = await updateZipCode(newZipCode, appointment.id);

        if (zipresponse.errors) {
          setErrorWithZip(true);
        } else if (zipresponse.message) {
          setErrorWithZip(false);
        }
      }
    } catch (error) {
      setLocationErr('Location must be updated');
    }
  };

  const handleZipCodeChanged = (newZipCode: string) => {
    updateZipCodeAsync(newZipCode);
  };

  const handleFixNow = async () => {
    mixPanel(MIXPANEL_TRACK.MECHANIC_IS_HERE_NOW);
    handleRespondAppointmentEstimate({
      appointment: {
        id: appointmentId,
        mechanic_already_here: '1',
      },
    });
  };

  useEffect(() => {
    const timer = setTimeout(async () => {
      if (!appointmentId) return;

      const resp: ResponseAvailability = await checkAvailability(appointmentId);

      if (!resp || !resp.data) {
        return;
      }

      const avail = resp.data.attributes.availability;

      setTimeSlots(avail);
      const timeSlotEntries = Object.entries(avail);
      if (timeSlotEntries.length > 0 && timeSlotEntries[0].length > 0) {
        changeDate(moment(timeSlotEntries[0][0]));
      }
    });

    return () => {
      clearTimeout(timer);
    };
  }, [appointmentId, appointmentStatus, isMechanicEstimate, isOfficeEstimate]);

  const fontClasses = fontStyles();

  const handleSchedule = () => {
    if (!selectedTimeSlotValue) {
      timeSlotRef.current?.scrollIntoView();
      setTimeErr('Time is required');
      setTimeout(() => {
        setTimeErr('');
      }, 4000);
    } else if (!location.exact_address && !isMechanicEstimate) {
      locationRef.current?.scrollIntoView();
      setLocationErr('Location is required');
      setTimeout(() => {
        setLocationErr('');
      }, 4000);
    } else if (errorWithZip) {
      locationRef.current?.scrollIntoView();
      setLocationErr('');
      setTimeout(() => {
        setLocationErr('');
      }, 4000);
    } else if (isMechanicEstimate && !isOfficeEstimate) {
      mixPanel(MIXPANEL_TRACK.SCHEDULE_APPOINTMENT_BY_TECH);

      handleRespondAppointmentEstimate({
        appointment: {
          id: appointmentId,
          appointment_day: keyDate,
          appointment_time: selectedTimeSlotValue,
        },
      });
    } else {
      mixPanel(MIXPANEL_TRACK.SCHEDULE_APPOINTMENT);

      handleUpdateAppointmentTime({
        appointment_day: keyDate,
        appointment_time: selectedTimeSlotValue,
        type_of_site: location.type_of_site,
        hints_to_find: location.description,
        exact_address: location.exact_address,
        apartment_number: '',
        location_attributes: {
          zip: '',
          route: '',
          street_number: '',
          locality: '',
          administrative_area_level_one: '',
          administrative_area_level_two: '',
          country: '',
          postal_code: '',
        },
      });
    }
  };

  const handleChangeDate = (newDate: MaterialUiPickersDate) => {
    changeDate(newDate);
  };

  const handleLocationInputChange = (key: string, value: string) => {
    setLocation({
      ...location,
      [key]: value,
    });
  };

  const isDateDisabled = (day: MaterialUiPickersDate): boolean => {
    return (
      ((timeSlots && timeSlots[day?.format('YYYY-MM-DD') || '']) || [])
        .length <= 0
    );
  };

  const handleTimeSelectedChange = (
    event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) => {
    const v = event.target.value as number;

    setSelectedTimeSlotValue(timeSlotsToday[v]);
  };

  return (
    <Dialog
      open={show}
      onClose={onClose}
      aria-labelledby="responsive-dialog-title"
      scroll="body"
      className={classes.root}
      fullScreen
    >
      <Layout className={isSm ? classes.layout : ''}>
        {appointmentId && (
          <JobFeedbackModal
            id={appointmentId}
            open={feedbackOpen}
            onClose={() => setFeedbackOpen(false)}
            page="Scheduling"
            text="Let us know if there is a specific day or time that you have in mind and don't find available. We'll do our best to accommodate your scheduling needs."
          />
        )}

        <div className={classes.content}>
          <Container>
            <Box key="pick-date-time-title" flexDirection="row" display="flex">
              <h1
                className={isSm ? fontClasses.h4 : fontClasses.h1}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                Time & Location
              </h1>
            </Box>
            <Box key="pick-date-time" className={classes.boxDateTime}>
              {date && (
                <Box style={{ margin: '5px 0' }}>
                  <Box className={classes.iconFlex}>
                    <EventAvailable />
                    <Typography style={{ marginLeft: '6px' }}>
                      <span style={{ fontWeight: 600 }}>Date: </span>{' '}
                      {date?.format('MMM DD')}
                    </Typography>
                  </Box>
                </Box>
              )}
              <Box>
                <Box className={classes.containerDatepicker}>
                  <MDatePicker
                    date={date}
                    shouldDisableDate={isDateDisabled}
                    onChangeDate={handleChangeDate}
                  />
                </Box>

                <br />
                <Box style={{ margin: '10px 0 5px 0' }}>
                  <Box className={classes.iconFlex}>
                    <AlarmAdd />
                    <Typography style={{ marginLeft: '6px' }}>
                      <span style={{ fontWeight: 600 }}>Arrival window: </span>
                      {selectedTimeSlotValue}
                    </Typography>
                  </Box>
                </Box>
                <Box className={classes.containerTimeSlots}>
                  {timeSlotsToday.length > 0 ? (
                    <FormControl
                      fullWidth
                      style={{ background: '#fff', padding: '10px' }}
                    >
                      {timeErr && (
                        <Typography
                          style={{ margin: '10x 0', color: '#FF0000' }}
                        >
                          {timeErr}
                        </Typography>
                      )}

                      <div ref={timeSlotRef}>
                        <Select
                          renderValue={() => selectedTimeSlotValue}
                          style={{ border: 'none' }}
                          fullWidth
                          value={selectedTimeSlotValue}
                          onChange={handleTimeSelectedChange}
                        >
                          {timeSlotsToday.map((tm, index) => (
                            <MenuItem key={`tm-${tm}`} value={index}>
                              {tm}
                            </MenuItem>
                          ))}
                        </Select>
                      </div>
                    </FormControl>
                  ) : (
                    <Box className="icon-label-start">
                      <Typography>No times available</Typography>
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>

            {(!isMechanicEstimate || isOfficeEstimate) && (
              <Box key="pick-location" className={classes.boxDateTime}>
                <Box
                  key="pick-location-title"
                  flexDirection="row"
                  display="flex"
                >
                  <LocationOn color="primary" className="icon-label-start" />
                  <Typography className={classes.titleDatetime} noWrap>
                    Your vehicle location
                  </Typography>
                </Box>
                <FormHelperText style={{ margin: '5px 0px 5px 20px' }}>
                  Our technician will perform the service here
                </FormHelperText>
                <div ref={locationRef}>
                  {locationErr && (
                    <Typography style={{ margin: '10x 0', color: '#FF0000' }}>
                      {locationErr}
                    </Typography>
                  )}
                  <Box key="location-info-2" className={classes.boxLocation}>
                    <GoogleAddressInputWithStatus
                      start={<DirectionsCar color="secondary" />}
                      value={location.exact_address}
                      valueChanged={(val: string) =>
                        handleLocationInputChange('exact_address', val)
                      }
                      zipCodeChanged={handleZipCodeChanged}
                    />
                  </Box>
                  {errorWithZip && (
                    <Typography
                      style={{ color: 'red', marginLeft: 10, fontSize: 12 }}
                    >
                      We currently do not have technicians serving this address,
                      please choose another address
                    </Typography>
                  )}
                </div>
              </Box>
            )}
          </Container>

          <div className={classes.footer}>
            <div>
              <div className={clsx('row', fontClasses.p1)}>
                <span>Date: </span>
                <span>{date?.format('MMM DD')}</span>
              </div>
              <div className={clsx('row', fontClasses.p1)}>
                <span>Arrival window: </span>
                <span>{selectedTimeSlotValue}</span>
              </div>

              <Grid
                item
                md={12}
                sm={12}
                xs={12}
                className={classes.actionContainer}
              >
                <ButtonForward
                  title="Back"
                  key="Back"
                  color="default"
                  rounded
                  noIcon
                  transparent
                  size="large"
                  onClickHandler={() => {
                    if (isMechanicEstimate) {
                      handleShowModal(QuoteShowModal.HANDLE_ESTIMATE_RESPONSE);
                    } else if (!memberJob) {
                      handleShowModal(QuoteShowModal.JOIN_MEMBER);
                    } else {
                      handleShowModal(QuoteShowModal.REVIEW_QUOTE);
                    }
                  }}
                  className={classes.buttonBack}
                />

                <ButtonForward
                  title="Continue"
                  key="Continue"
                  rounded
                  size="large"
                  onClickHandler={handleSchedule}
                  disabled={requestInProgress}
                  processing={requestInProgress}
                />
              </Grid>
            </div>
            {isMechanicEstimate && (
              <ButtonForward
                className={classes.reservationButton}
                key="button-fix-now"
                color="default"
                title="My mechanic is here. Fix Now!"
                size="large"
                noIcon
                onClickHandler={handleFixNow}
              />
            )}
          </div>
        </div>
      </Layout>
    </Dialog>
  );
};

ModalScheduleService.defaultProps = {};

export default ModalScheduleService;
