import {
  RequestCreateAppointment,
  RequestUpdateAppointmentTime,
  RequestUpdateAppointmentContact,
  RequestConfirmAppointment,
  ResponseAppointment,
  ResponseCheckPlateNumber,
  ResponseVehicle,
  ResponseZipcode,
  ResponseAvailability,
  ResponseAppointmentEstimate,
  RequestUpdateEstimateResponse,
  RequestUpdateEstimateService,
  RequestAddEstimateService,
  RequestAuthPayPalDiag,
  RequestAuthPayPalRepair,
  RequestRescheduleAppointment,
  RequestChatConversation,
  ResponseChatConversation,
  ResponseInspectionReport,
  OpenAIResponse,
  ResponseGetUser,
  RequestNewCar,
  ResponseEstimate,
  ResponseAppointmentForReview,
  ResponseCheckVin,
  ResponseZip,
  VirtualDiagnosisFormData,
  RequestUpdateZip,
  UpdateCar,
} from 'src/types';
import { callApi } from './request';

export const getHappyCustomer = async (
  zip: string
): Promise<ResponseZipcode> => {
  return callApi<ResponseZipcode>({
    url: '/api/v1/appointments/zip_code',
    method: 'POST',
    data: {
      zip_code: zip,
    },
  });
};

export const getVehicles = async (
  year: string,
  make: string | undefined,
  model: string | undefined
): Promise<ResponseVehicle> => {
  return callApi<ResponseVehicle>({
    url: `/api/v2/vehicles`,
    params: {
      year,
      make,
      model,
    },
    method: 'GET',
  });
};

export const checkOutOfCoverage = async (zipcode: any): Promise<any> => {
  return callApi<any>({
    url: `/api/v2/appointments/check_out_of_coverage`,
    params: {
      zipcode,
    },
    method: 'GET',
  });
};

export const checkPlateNumber = async (
  plate: string,
  state: string
): Promise<ResponseCheckPlateNumber> => {
  return callApi<ResponseCheckPlateNumber>({
    url: `/api/v2/vehicles/plate/${plate}/${state}`,
    method: 'GET',
  });
};

export const checkVin = async (vin: string): Promise<ResponseCheckVin> => {
  return callApi<ResponseCheckVin>({
    url: `/api/v2/vehicles/vin/${vin}`,
    method: 'GET',
  });
};

export const checkAvailability = async (
  id: number
): Promise<ResponseAvailability> => {
  return callApi<ResponseAvailability>({
    url: `/api/v2/availability/${id}`,
    method: 'GET',
  });
};

export const checkAvailabilityZip = async (
  zip: string,
  appointment_type: string
): Promise<ResponseAvailability> => {
  return callApi<ResponseAvailability>({
    url: '/api/v2/availability_by_zip',
    method: 'GET',
    params: {
      zip,
      appointment_type,
    },
  });
};

export const getAppointment = async (
  id: string
): Promise<ResponseAppointment> => {
  return callApi<ResponseAppointment>({
    url: `/api/v1/appointments/${id}`,
    method: 'GET',
  });
};

export const getAppointmentForReview = async (
  id: string
): Promise<ResponseAppointmentForReview> => {
  return callApi<ResponseAppointmentForReview>({
    url: `/api/v1/appointments/${id}/review`,
    method: 'GET',
  });
};

export const createAppointment = async (
  data: RequestCreateAppointment
): Promise<ResponseAppointment> => {
  return callApi<ResponseAppointment>({
    url: `/api/v2/appointments`,
    method: 'POST',
    data,
  });
};

export const createFleetAppointment = async (
  data: RequestCreateAppointment
): Promise<ResponseAppointment> => {
  return callApi<ResponseAppointment>({
    url: `/api/v2/appointments/create_fleet`,
    method: 'POST',
    data,
  });
};

export const updateAppointment = async (
  id: number,
  data:
    | RequestUpdateAppointmentTime
    | RequestUpdateAppointmentContact
    | RequestCreateAppointment
    | RequestUpdateZip
): Promise<ResponseAppointment> => {
  return callApi<ResponseAppointment>({
    url: `/api/v2/appointments/${id}`,
    method: 'PUT',
    data,
  });
};

export const updateAppointmentContactInfo = async (
  id: number,
  data: RequestUpdateAppointmentContact
): Promise<ResponseAppointment> => {
  return callApi<ResponseAppointment>({
    url: `/api/v2/appointments/contact/${id}`,
    method: 'PUT',
    data,
  });
};

export const updateAppointmentOutOfRange = async (
  id: number,
  zip: string
): Promise<ResponseAppointment> => {
  return callApi<ResponseAppointment>({
    url: `/api/v2/appointments/update_out_of_range/${id}`,
    method: 'PUT',
    data: { zip },
  });
};

export const updateAppointmentCallUsTime = async (
  id: number
): Promise<ResponseAppointment> => {
  return callApi<ResponseAppointment>({
    url: `/api/v2/appointments/${id}/call_us_clicked`,
    method: 'POST',
  });
};

export const cancelAppointment = async (
  id: string,
  reason: string,
  reasonSelect: string
): Promise<ResponseAppointment> => {
  return callApi<ResponseAppointment>({
    url: `/api/v1/appointments/${id}`,
    method: 'PATCH',
    data: {
      appointment: {
        cancel_reason: reason,
        cancel_reason_select: reasonSelect,
        status: 'cancelled',
      },
    },
  });
};

export const updateAppointmentEstimate = async (
  id: number,
  data: RequestUpdateEstimateResponse
): Promise<ResponseAppointment> => {
  return callApi<ResponseAppointment>({
    url: `/api/v2/appointments/estimate_booking/${id}`,
    method: 'PATCH',
    data,
  });
};

export const rescheduleAppointment = async (
  id: number,
  data: RequestRescheduleAppointment
): Promise<ResponseAppointment> => {
  return callApi<ResponseAppointment>({
    url: `/api/v1/user/reschedule/${id}`,
    method: 'PATCH',
    data,
  });
};

export const confirmAppointment = async (
  id: number,
  data: RequestConfirmAppointment
): Promise<ResponseAppointment> => {
  return callApi<ResponseAppointment>({
    url: `/api/v2/appointments/confirm/${id}`,
    method: 'POST',
    data,
  });
};

export const confirmPayPal = async (
  id: number,
  is_not_booking: boolean,
  payment_detail: RequestAuthPayPalDiag | RequestAuthPayPalRepair
): Promise<ResponseAppointment> => {
  return callApi<ResponseAppointment>({
    url: `/api/v2/appointments/confirm_paypal/${id}`,
    method: 'POST',
    data: {
      is_not_booking,
      payment_detail,
    },
  });
};

export const confirmCardOnFile = async (
  id: number
): Promise<ResponseAppointment> => {
  return callApi<ResponseAppointment>({
    url: `/api/v2/appointments/confirm_card_on_file/${id}`,
    method: 'POST',
  });
};

export const apiApplyReferralCode = async (
  id: number,
  code: string
): Promise<ResponseAppointment> => {
  return callApi<ResponseAppointment>({
    url: `/api/v2/coupons/${code}/${id}`,
    method: 'GET',
  });
};

export const updateCard = async (
  id: number,
  token: string
): Promise<ResponseAppointment> => {
  return callApi<ResponseAppointment>({
    url: '/api/v1/payments/update_cc',
    method: 'POST',
    data: { appointment_id: id, token },
  });
};

export const acceptEstimate = async (
  id: number | null,
  member_price_agreed_on_before_job?: number | null
): Promise<ResponseAppointmentEstimate> => {
  let url = `/api/v1/estimate_responses?&estimate_response[estimate_id]=${id}`;

  if (member_price_agreed_on_before_job) {
    url += `&member_price_agreed_on=${member_price_agreed_on_before_job}`;
  }

  return callApi<ResponseAppointmentEstimate>({
    url,
    method: 'POST',
  });
};

export const updateEstimateServiceStatus = async (
  id: number,
  data: RequestUpdateEstimateService
): Promise<ResponseEstimate> => {
  return callApi<ResponseEstimate>({
    url: `/api/v1/estimate_services/${id}`,
    method: 'PUT',
    data,
  });
};

export const updateEstimateServiceStatusV2 = async (
  id: number,
  data: RequestUpdateEstimateService
): Promise<ResponseAppointment> => {
  return callApi<ResponseAppointment>({
    url: `/api/v2/estimate_services/${id}`,
    method: 'PUT',
    data,
  });
};

export const addEstimateService = async (
  id: number,
  data: RequestAddEstimateService
): Promise<ResponseAppointment> => {
  return callApi<ResponseAppointment>({
    url: `/api/v1/estimate_services/add_service/${id}`,
    method: 'POST',
    data,
  });
};

export const getInspectionReport = async (
  id: string
): Promise<ResponseInspectionReport> => {
  return callApi<ResponseInspectionReport>({
    url: `/api/v1/inspection_reports/${id}/for_pdf`,
    method: 'GET',
  });
};

export const generateNewMessage = async (
  prompt: string,
  fallback: string
): Promise<OpenAIResponse> => {
  return callApi({
    url: '/api/v2/chat_conversations/generate_message',
    method: 'POST',
    data: {
      prompt,
      fallback,
    },
  });
};

export const cancellationDiscount = async (
  id: number
): Promise<ResponseAppointment> => {
  return callApi<ResponseAppointment>({
    url: `/api/v2/appointments/${id}/cancellation_discount`,
    method: 'POST',
  });
};

export const removeEstimateService = async (
  id: number
): Promise<ResponseAppointment> => {
  return callApi<ResponseAppointment>({
    url: `/api/v1/estimate_services/${id}`,
    method: 'DELETE',
  });
};

export const createJobFeedback = async (
  id: number,
  feedback: string,
  page: string
): Promise<ResponseAppointment> => {
  return callApi<ResponseAppointment>({
    url: '/api/v1/job_feedbacks',
    method: 'POST',
    data: {
      appointment_id: id,
      feedback,
      page,
    },
  });
};

export const createChatConversation = async (
  data: RequestChatConversation
): Promise<ResponseChatConversation> => {
  return callApi<ResponseChatConversation>({
    url: '/api/v2/chat_conversations',
    method: 'POST',
    data,
  });
};

export const postVirtualDiagnosisLead = async (
  lead: VirtualDiagnosisFormData,
  token: string
): Promise<ResponseChatConversation> => {
  return callApi<ResponseChatConversation>({
    url: '/api/v1/leads',
    method: 'POST',
    data: {
      lead,
      token,
    },
  });
};

export const getChatConversation = async (
  id: number
): Promise<ResponseChatConversation> => {
  return callApi<ResponseChatConversation>({
    url: `/api/v2/chat_conversations/${id}`,
    method: 'GET',
  });
};

export const updateCar = async (
  id: number,
  name: string
): Promise<ResponseGetUser> => {
  return callApi<ResponseGetUser>({
    url: `/api/v2/user/cars/${id}`,
    method: 'PUT',
    data: {
      car: {
        name,
      },
    },
  });
};

export const updateCarWithProps = async (
  car: UpdateCar
): Promise<ResponseGetUser> => {
  return callApi<ResponseGetUser>({
    url: `/api/v2/user/cars/${car.id}`,
    method: 'PUT',
    data: car,
  });
};

export const createCar = async (
  car: RequestNewCar
): Promise<ResponseGetUser> => {
  return callApi<ResponseGetUser>({
    url: '/api/v2/user/cars',
    method: 'POST',
    data: car,
  });
};

export const createAppointmentFromReminder = async (
  id: number,
  car_id: number,
  service: string,
  day: string
): Promise<ResponseAppointment> => {
  return callApi<ResponseAppointment>({
    url: `/api/v2/user/appointments/create_from_reminder/${id}`,
    method: 'POST',
    data: {
      car_id,
      service,
      day,
    },
  });
};

export const updateZipCode = async (
  zipcode: string,
  id: number
): Promise<ResponseZip> => {
  return callApi({
    url: `/api/v2/availability/update_zipcode/${id}`,
    method: 'PUT',
    params: {
      zipcode,
      id,
    },
  });
};
