import {
  Box,
  Button,
  Card,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  IconButton,
  Link,
  makeStyles,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core';
import callApi from 'src/api/request';
import { ArrowForward, Star, StarBorder } from '@material-ui/icons';
import { MEMBER_PLANS, MEMBER_PRICES } from 'src/utils/consts';
import { getAppointmentForReview } from 'src/api/quote';
import { useParams } from 'react-router-dom';
import React, { ReactElement, useEffect, useState } from 'react';
import { IAppointmentForReview } from 'src/types';
import mixPanel from 'src/utils/mixpanel';
import AppleDownload from 'src/assets/iOS.svg';
import GoogleDownload from 'src/assets/play-badge.svg';
import pocket from 'src/assets/referrals/pocket.svg';
import { Image } from 'src/components/atoms';
import review from '../../api/review';

interface MemberPlan {
  name: string;
  price: number;
  billing: string;
  billing_interval: string;
  billing_count: number;
}

interface MembershipRequest {
  user_id: number | undefined;
  plan_name: string;
  billing_interval: string;
  billing_count: number;
  price_sold: number;
  sold_by: string;
  vehicles: string[];
}

const useStyles = makeStyles(() => ({
  root: {
    margin: '4rem auto',
    height: '100vh',
    width: '90%',
    paddingBottom: '10rem',
    '& .MuiToggleButton-root': {
      color: 'black',
    },
    '& .MuiToggleButton-root.Mui-selected': {
      color: 'rgba(109, 89, 249, 1)',
      backgroundColor: 'rgba(109, 89, 249, 0.12)',
    },
    '& .MuiOutlinedInput-input': {
      border: 0,
      fontFamily: 'Inter',
      fontSize: 17,
      fontWeight: 400,
    },
    '& section': {
      marginBlock: '10px',
    },
    '& .MuiTypography-body1': {
      fontFamily: 'Inter',
    },
  },
  cardOne: {
    maxWidth: 450,
    display: 'flex',
    background: '#FFFFFF',
    justifyContent: 'center',
    margin: 'auto',
    marginTop: 50,
    padding: 20,
    flexDirection: 'column',
  },
  chip: {
    fontSize: 12,
    padding: 'auto',
    fontWeight: 700,
    color: '#005959',
    background: '#D4E6B5',
  },
  boxOne: {
    margin: 10,
    padding: 20,
  },
  fontBig: {
    fontWeight: 'bold',
    margin: '10px 0',
  },
  Star: {
    marginTop: 15,
  },
  buttonForward: {
    color: '#fff',
    padding: '6px 24px',
    fontFamily: 'inter',
    borderRadius: '32px',
    textTransform: 'none',
    background: '#005959',
    width: 'min-content',
    margin: 'auto',
    '&:hover': {
      background: '#005959',
    },
  },
  arrow: {
    marginLeft: 5,
  },

  referButton: {
    '& .MuiButton-label': {
      width: '20%',
    },
  },
  hdiv: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: 'Inter',
    marginBottom: 20,
    fontWeight: 700,
  },
  appleIcon: {
    width: '190px',
    height: '58px',
  },
  star: {
    color: 'gold',
    cursor: 'pointer',
  },
}));

const ReviewNew = (): ReactElement => {
  const classes = useStyles();
  const { appId }: { appId: string } = useParams();
  const [step, setStep] = useState(1);
  const [form, setForm] = useState({
    rating: 0,
    feedback: '',
  });
  const [appointment, setAppointment] = useState<IAppointmentForReview>();

  useEffect(() => {
    if (!appointment && appId) {
      mixPanel('Review loaded');
      getAppointmentForReview(appId)
        .then((response) => {
          if (response && response.data) {
            setAppointment(response.data);
          }
        })
        .catch(() => {});
    }
  }, [appId, appointment]);

  const [err, setErr] = useState(false);
  const [requesting, setRequesting] = useState(false);
  const [plan, setPlan] = useState<MemberPlan>(MEMBER_PLANS[0]);
  const [creatingMembership, setCreatingMembership] = useState(false);
  const [memberSuccess, setMemberSuccess] = useState(false);
  const [error, setError] = useState<string | null>();

  const handleNext = () => {
    setStep((prevStep) => prevStep + 1);
  };

  const handleStarClick = (clickedRating: number) => {
    setForm((prevForm) => ({
      ...prevForm,
      rating: clickedRating,
    }));
  };

  const planPriceText = () => {
    if (plan.price === 180) {
      return '$30 / month';
    }

    return '';
  };

  const renderRateButton = () => {
    const userAgent = navigator.userAgent || navigator.vendor;
    let appStoreLink: string | undefined;

    if (/iPad|iPhone|iPod/.test(userAgent)) {
      appStoreLink =
        'https://itunes.apple.com/app/id/6449040907?action=write-review';
    } else if (/android/i.test(userAgent)) {
      appStoreLink =
        'https://play.google.com/store/apps/details?id=com.anonymous.fmcmemberapp&showAllReviews=true';
    }

    if (appStoreLink) {
      return (
        <Box style={{ margin: '10px 0' }}>
          <Box style={{ margin: '10px auto', textAlign: 'center' }}>
            <Link href={appStoreLink}>
              <Button variant="contained" color="primary">
                Rate Now
              </Button>
            </Link>
          </Box>
        </Box>
      );
    }
    return (
      <>
        <Box style={{ margin: '10px auto', textAlign: 'center' }}>
          <Link href="https://itunes.apple.com/app/id/6449040907?action=write-review">
            <Button variant="contained" color="primary">
              Rate on App Store
            </Button>
          </Link>
        </Box>
        <Box style={{ margin: '10px auto', textAlign: 'center' }}>
          <Link href="https://play.google.com/store/apps/details?id=com.anonymous.fmcmemberapp&showAllReviews=true">
            <Button variant="contained" color="primary">
              Rate on Google Play
            </Button>
          </Link>
        </Box>
      </>
    );
  };

  const handleSubmit = () => {
    const reviewRequest = {
      id: appId,
      rating_text: form.rating,
      feedback: form.feedback,
    };

    setRequesting(true);

    review
      .submitReview(reviewRequest)
      .then((res) => {
        if (res.errors) {
          setErr(true);

          setTimeout(() => {
            setErr(false);
          }, 4000);
        } else {
          mixPanel('Review submitted');
          if (form.rating >= 4) {
            const userAgent = navigator.userAgent || navigator.vendor;
            let appStoreLink: string | undefined;

            if (/iPad|iPhone|iPod/.test(userAgent)) {
              appStoreLink =
                'https://itunes.apple.com/app/id/6449040907?action=write-review';
            } else if (/android/i.test(userAgent)) {
              appStoreLink =
                'https://play.google.com/store/apps/details?id=com.anonymous.fmcmemberapp&showAllReviews=true';
            }

            if (appStoreLink) {
              window.location.href = appStoreLink;
            } else {
              setStep((prevStep) => prevStep + 1);
            }
          } else {
            setStep((prevStep) => prevStep + 2);
          }
        }
      })
      .finally(() => {
        setRequesting(false);
      });
  };

  return (
    <div className={classes.root}>
      {step === 1 && (
        <section>
          <Card className={classes.cardOne} elevation={3}>
            <Typography variant="h4" className={classes.fontBig}>
              Rate your Experience
            </Typography>
            <Typography variant="body1" gutterBottom>
              How satisfied are you with your service?
            </Typography>
            <div style={{ margin: '10px 0' }}>
              {[1, 2, 3, 4, 5].map((starIndex) => (
                <IconButton
                  key={starIndex}
                  onClick={() => handleStarClick(starIndex)}
                  className={classes.star}
                >
                  {starIndex <= form.rating ? (
                    <Star fontSize="large" />
                  ) : (
                    <StarBorder fontSize="large" color="disabled" />
                  )}
                </IconButton>
              ))}
            </div>

            <section>
              <Typography variant="body1" gutterBottom>
                Tell us how we can improve.
              </Typography>
              <TextField
                multiline
                rows={4}
                placeholder="Type here"
                style={{ width: '100%' }}
                value={form.feedback}
                onChange={(e) => {
                  setForm((prevForm) => ({
                    ...prevForm,
                    feedback: e.target.value,
                  }));
                }}
              />
            </section>

            <Button
              disabled={!form.rating || requesting}
              style={{
                margin: '10px 0',
              }}
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              className={classes.buttonForward}
            >
              Continue
              <ArrowForward className={classes.arrow} />
            </Button>
            {err && (
              <div
                style={{
                  paddingTop: '8px',
                }}
              >
                <Typography
                  color="error"
                  variant="body2"
                  style={{ marginBottom: '10px' }}
                >
                  An error occured. Please try again later or contact us at{' '}
                  <Link
                    color="error"
                    href="tel:469-840-3990"
                    underline="always"
                  >
                    469-840-3990
                  </Link>
                </Typography>
              </div>
            )}
          </Card>
        </section>
      )}

      {step === 2 && (
        <section>
          <Card className={classes.cardOne} elevation={3}>
            <Typography variant="h4" className={classes.fontBig}>
              Rate Our Mobile App
            </Typography>
            <Typography variant="body1" gutterBottom>
              Take a second to rate our mobile app.
            </Typography>
            {renderRateButton()}
            <Button
              style={{ marginTop: '10px' }}
              variant="outlined"
              onClick={() => {
                handleNext();
                mixPanel('Membership rate step passed');
              }}
            >
              Skip to Membership
            </Button>
          </Card>
        </section>
      )}

      {step === 3 && (
        <section>
          <Card className={classes.cardOne} elevation={3}>
            <div className={classes.hdiv}>
              <img src={pocket} alt="logo" style={{ marginRight: 10 }} />
              <Typography variant="h4" gutterBottom>
                Goodhood Membership
              </Typography>
            </div>
            <Box>
              <Typography style={{ margin: '5px 0' }} variant="body1">
                Join now and get{' '}
                <span style={{ fontStyle: 'bold' }}>
                  10% off all services and FREE oil changes.
                </span>
              </Typography>

              <Box style={{ margin: '20px 0' }}>
                <Typography variant="body1">
                  <span style={{ color: '#005959' }}>{planPriceText()}</span>{' '}
                </Typography>
                <FormHelperText style={{ fontFamily: 'Inter' }}>
                  {plan.price === 180 ? '* billed $180 every 6 months' : ''}
                </FormHelperText>

                <Button
                  disabled={creatingMembership}
                  color="primary"
                  fullWidth
                  variant="contained"
                  style={{ margin: '20px 0 5px 0' }}
                  onClick={() => {
                    setCreatingMembership(true);

                    const vehicles = [];

                    if (
                      appointment?.attributes.user_cars_list &&
                      appointment.attributes.user_cars_list.length > 0
                    ) {
                      vehicles.push(appointment?.attributes.user_cars_list[0]);
                    }

                    const membership: MembershipRequest = {
                      user_id: appointment?.attributes.user_id,
                      plan_name: plan.name,
                      billing_interval: plan.billing_interval,
                      billing_count: plan.billing_count,
                      price_sold: plan.price,
                      sold_by: 'Review Page',
                      vehicles,
                    };

                    callApi({
                      url: 'api/v2/memberships/create_for_existing_user',
                      method: 'POST',
                      data: {
                        membership,
                      },
                    })
                      .then((e: any) => {
                        if (e.errors) {
                          setError(e.errors.join(', '));
                        } else {
                          mixPanel('Membership signup from review');
                          setMemberSuccess(true);
                          setTimeout(() => {
                            handleNext();
                          }, 3000);
                        }

                        setCreatingMembership(false);
                      })
                      .then(() => handleNext);
                  }}
                >
                  Join Now
                </Button>

                {error && (
                  <Typography style={{ margin: '10px 0' }} color="primary">
                    Error signing up to be a member, please contact us at{' '}
                    <a href="tel:469-840-3990">469-840-3990</a>
                  </Typography>
                )}
                {memberSuccess && (
                  <Typography style={{ margin: '10px 0' }} color="primary">
                    Success! Thanks for becoming a valued member.
                  </Typography>
                )}
              </Box>
            </Box>
            <Button
              variant="outlined"
              onClick={() => {
                handleNext();
                mixPanel('Membership skipped from review');
              }}
            >
              Skip to Download App
            </Button>
          </Card>
        </section>
      )}
      {step === 4 && (
        <section>
          {' '}
          <Card className={classes.cardOne} elevation={3}>
            <section>
              <div className={classes.hdiv}>
                <img src={pocket} alt="logo" style={{ marginRight: 10 }} />
                <Typography variant="h4" gutterBottom>
                  Download the App
                </Typography>
              </div>

              <Box style={{ margin: '10px 0' }}>
                <Typography variant="body1" gutterBottom>
                  To stay updated with your vehicle, download our Goodhood
                  mobile app.
                </Typography>

                <Box style={{ margin: '40px 0' }}>
                  <Box style={{ margin: '10px auto', textAlign: 'center' }}>
                    <Link href="https://apps.apple.com/us/app/goodhood-auto-club/id6449040907">
                      <Image
                        className={classes.appleIcon}
                        src={AppleDownload}
                      />
                    </Link>
                  </Box>

                  <Box style={{ margin: '10px auto', textAlign: 'center' }}>
                    <Link href="https://play.google.com/store/apps/details?id=com.anonymous.fmcmemberapp">
                      <Image src={GoogleDownload} />
                    </Link>
                  </Box>
                </Box>
              </Box>
            </section>
          </Card>
        </section>
      )}
    </div>
  );
};

export default ReviewNew;
